import PlatformHelper from 'helpers/PlatformHelper';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { closeDialog, debounce, getDOMCls } from '../helpers';

export const useAttachCloseOnClickToOverlay = (): void => {
  useEffect(() => {
    // const elOverlayArr = Array.from(document.querySelectorAll(".menu, .dialog"));
    const overlay = document.querySelector('#whole-overlay');
    const onOverlayClick = (e: Event) => {
      if (overlay === e.target) {
        e.preventDefault();
        e.stopImmediatePropagation();
        closeDialog();
      }
    };
    const overlayBoundEvents = 'click touchstart'.split(' ');
    overlayBoundEvents.forEach((e) => {
      window.addEventListener(e, onOverlayClick, false);
    });
  }, []);
};

export const useCloseMobileMenu = (): void => {
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (!PlatformHelper.isMobile) {
        const mobileMenuCls = 'mobile-menu-open';
        const htmlClsList = getDOMCls();
        htmlClsList.remove(mobileMenuCls);
      }
    });
  }, []);
};

export function useOnClickOutside<T extends HTMLElement>(ref: MutableRefObject<T>, handler: (e: Event) => void): void {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      // console.log("ref.current ", ref.current);
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }

      handler(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export function useOnScrollElement(elementTop: number | null): boolean {
  const [scrolled, setScrolled] = useState(false);

  const removeWindowScrollEvent = () => {
    window.removeEventListener('scroll', debouncedScroll, false);
  };

  const handleScroll = useCallback(() => {
    if (elementTop === null) {
      return;
    }
    const isTop = elementTop <= window.scrollY + window.innerHeight + 100;
    if (isTop && !scrolled) {
      setScrolled(true);
      // unsubscribe from scrolling after we already scrolled!
      removeWindowScrollEvent();
    }
  }, [scrolled, elementTop]);

  // throttle scroll sniffing to every ~3rd render frame (1000ms / 60fps * 3) to increase performance
  const debouncedScroll = debounce(handleScroll, 50);

  useEffect(() => {
    if (!scrolled && elementTop !== null) {
      window.addEventListener('scroll', debouncedScroll, false);
      debouncedScroll();
      return () => {
        removeWindowScrollEvent();
      };
    }
  }, [elementTop, scrolled, debouncedScroll]);

  return scrolled;
}
