import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Layout from '../Layout';
import styles from './index.module.scss';

export const ProfileSkeleton: React.FC = () => {
  const { t, lang } = useTranslation();

  return (
    <Layout countryCode={lang}>
      <main className={styles.account}>
        <SkeletonTheme baseColor="#22222E" highlightColor="#2D2D3A">
          <div
            style={{ paddingBottom: 100, paddingTop: 148, width: 770, maxWidth: '100%' }}
            className="d-flex align-items-center justify-content-start ml-auto mr-auto"
          >
            <Skeleton className="d-block" style={{ width: 70, height: 70, borderRadius: '100%', marginRight: 32 }} />
            <div>
              <h1>
                <Skeleton style={{ width: 220, height: 48, marginBottom: 8 }} />
              </h1>
              <p className="mb-0">
                <Skeleton style={{ width: 195, height: 22 }} />
              </p>
            </div>
          </div>

          <div className={styles.subContainer}>
            <h2>{t('profile:subscription')}</h2>

            <h3>
              <Skeleton style={{ width: 240, height: 33 }} />
            </h3>
            <p style={{ marginBottom: 42 }}>
              <Skeleton style={{ width: 320, height: 27 }} />
            </p>

            <div className="row">
              <div className="col-md-4">
                <Skeleton style={{ width: '100%', height: 83 }} />
              </div>
              <div className="col-md-4">
                <Skeleton style={{ width: '100%', height: 83 }} />
              </div>
              <div className="col-md-4">
                <Skeleton style={{ width: '100%', height: 83 }} />
              </div>
            </div>
          </div>

          <div className={styles.forms} style={{ paddingBottom: 80 }}>
            <div className="row">
              <div className="col-md-6">
                <form onSubmit={() => false}>
                  <h2 style={{ marginBottom: 30 }}>{t('profile:personal_details')}</h2>
                  <label>{t('profile:full_name')}</label>
                  <Skeleton className="d-block" style={{ width: '100%', height: 50, marginBottom: 12 }} />
                  <label>{t('profile:ui_language')}</label>
                  <Skeleton className="d-block" style={{ width: '100%', height: 50, marginBottom: 12 }} />
                </form>
              </div>
              <div className="col-md-6">
                <form onSubmit={() => false}>
                  <h2 style={{ marginBottom: 30 }}>{t('profile:password_change')}</h2>
                  <label>{t('profile:actual_password')}</label>
                  <Skeleton className="d-block" style={{ width: '100%', height: 50, marginBottom: 12 }} />
                  <label>{t('profile:new_password')}</label>
                  <Skeleton className="d-block" style={{ width: '100%', height: 50, marginBottom: 12 }} />
                  <label>{t('profile:new_password_again')}</label>
                  <Skeleton className="d-block" style={{ width: '100%', height: 50, marginBottom: 12 }} />
                </form>
              </div>

              <div className={classNames('col-md-12', styles.forms)} style={{ marginTop: 80, paddingBottom: 80 }}>
                <h2>{t('profile:email_newsletter')}</h2>
                <p style={{ marginBottom: 40 }}>{t('profile:email_newsletter_text')}</p>
                <Skeleton className="d-block" style={{ width: 200, height: 50, marginBottom: 12 }} />
              </div>

              <div className={classNames('col-md-12', styles.forms)}>
                <h2 style={{ marginBottom: 30 }}>{t('profile:purchase_history')}</h2>

                {Array.from(Array(4).keys()).map((_, i) => {
                  return (
                    <div key={i} style={{ borderBottom: '1px solid rgba(255, 255, 255, .12)', padding: '20px 0' }}>
                      <Skeleton className="d-block" style={{ width: 80, height: 18, marginBottom: 3 }} />
                      <Skeleton className="d-block" style={{ width: 140, height: 25, marginBottom: 3 }} />
                      <Skeleton className="d-block" style={{ width: 100, height: 16, marginBottom: 0 }} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.forms}>
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ fontSize: '155.55555556%', marginBottom: 30 }}>{t('profile:delete_account')}</h2>
                <p style={{ opacity: 0.7, marginBottom: 40 }}>{t('profile:delete_account_text')}</p>

                <Skeleton style={{ width: 152, height: 50 }} />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </main>
    </Layout>
  );
};
