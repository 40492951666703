import slugify from 'slugify';

export function twoLetterShortName(str: string): string {
  const names = (str || '').trim().split(' ');
  return [names[0], names[names.length - 1]]
    .filter((s) => !!s)
    .map((s) => s[0].toUpperCase())
    .join('');
}

export function slugifyString(str: string): string {
  if (!str) {
    return null;
  }

  return slugify(str, { lower: true, replacement: '-', remove: /[".,]/g, strict: true });
}
