import { Events } from 'enums';
import jsSHA from 'jssha';
import { GetServerSidePropsContext } from 'next';
import { setCookie } from 'nookies';
import { StaticPathParams } from 'typings/staticPaths';
import { v4 as uuidv4 } from 'uuid';

export function forceLanguage(lang: string, ctx?: GetServerSidePropsContext<StaticPathParams>): void {
  setCookie(ctx, 'NEXT_LOCALE', lang, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });

  if (ctx) {
    ctx.locale = lang;
  } else if (process.browser) {
    window.dispatchEvent(new CustomEvent(Events.Language, { detail: lang }));
  }
}

export function signCookie(option: string): [string, string] {
  const userAgent = navigator.userAgent;
  const uuid = uuidv4();

  const sha256 = new jsSHA('SHA-256', 'TEXT');
  sha256.update(`${option},${userAgent},${uuid}`);

  return [sha256.getHash('HEX'), uuid];
}
