export enum AppApiError {
  PatchUserError = 'PatchUserError',
  RedeemVoucherError = 'RedeemVoucherError',
  SubscriptionPaymentError = 'SubscriptionPaymentError',
  DonationError = 'DonationError',
  VoucherPaymentError = 'VoucherPaymentError',
  PaymentNeeded = 'PaymentNeeded',
  AddCard = 'AddCard',
  UserError = 'UserError',
  QueryParamsMissing = 'QueryParamMissing',
  AuthInvalid = 'AuthInvalid',
  CodeNoLongerValid = 'CODE_NO_LONGER_VALID',
  CodeDoesNotExist = 'CODE_DOES_NOT_EXIST',
  DiscountAlreadyUsed = 'DISCOUNT_ALREADY_USED',
  OtherDiscountIsActive = 'DISCOUNT_ACTIVE',
  PromoReferralNotAllowed = 'PROMO_REFERRAL_NOT_ALLOWED',
  PromoAlreadyUsed = 'PROMO_ALREADY_USED',
  PromoNotAllowed = 'PROMO_NOT_ALLOWED',
  VoucherAlreadyUsed = 'VOUCHER_ALREADY_USED',
  VoucherExpired = 'VOUCHER_EXPIRED',
  VoucherDoesNotExist = 'VOUCHER_DOES_NOT_EXIST',
}

export enum LoginErrorType {
  WrongPassword = 'auth/wrong-password',
  UserNotFound = 'auth/user-not-found',
}
