import { useEffect, useState } from 'react';
import Swiper from 'swiper';
import Router from 'next/router';

export const useCategoryRowSwiper = (ref: React.MutableRefObject<HTMLElement>, playlist?: unknown): void => {
  useEffect(() => {
    if (ref.current) {
      new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        navigation: {
          nextEl: ref.current.querySelector('.next') as HTMLElement,
          prevEl: ref.current.querySelector('.prev') as HTMLElement,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
    }
  }, [ref, playlist]);
};

export const useFilterTheaters = (ref: React.MutableRefObject<HTMLElement>): Swiper => {
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (ref.current) {
      const instance = new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
      setSwiper(instance);
    }
  }, [ref]);
  return swiper;
};

export const useTheatreRow = (ref: React.MutableRefObject<HTMLElement>, row?: unknown): void => {
  useEffect(() => {
    if (ref.current) {
      new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
    }
  }, [ref, row]);
};

export const usePlayRow = (ref: React.MutableRefObject<HTMLElement>, load: boolean): void => {
  useEffect(() => {
    let swiper: Swiper;
    if (ref.current && load) {
      swiper = new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
    }
    return () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
    };
  }, [ref, load]);
};

export const useWelcomeTheatresRow = (
  ref: React.MutableRefObject<HTMLElement>,
  theaters: unknown[],
  rendered: boolean,
): void => {
  useEffect(() => {
    if (ref.current && window?.innerWidth < 992 && rendered) {
      new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
    }
  }, [ref, theaters, rendered]);
};

let useHowToItemsInstance: Swiper;
export const useHowToItems = (
  ref: React.MutableRefObject<HTMLDivElement>,
  rendered: boolean,
  initialSlide = 0,
  windowWidth = 0,
): void => {
  useEffect(() => {
    useHowToItemsInstance && useHowToItemsInstance.destroy(true, true);
    if (windowWidth < 992 && rendered) {
      useHowToItemsInstance = new Swiper(ref.current, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        initialSlide,
        centeredSlides: true,
        spaceBetween: 30,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      });
      useHowToItemsInstance.on('slideChangeTransitionEnd', () => {
        Router.push(useHowToItemsInstance.slides[useHowToItemsInstance.activeIndex].querySelector('a').href);
      });
    }
  }, [ref, rendered, initialSlide, windowWidth]);
};

export const useFade = (ref: React.MutableRefObject<HTMLElement>, items: unknown[]): void => {
  useEffect(() => {
    new Swiper(ref.current, {
      fadeEffect: { crossFade: true },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      slidesPerView: 1,
      effect: 'fade',
      keyboard: {
        enabled: false,
      },
    });
  }, [ref, items]);
};
