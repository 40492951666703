import { Translate } from 'next-translate';
import { RouteType } from 'enums/routeType';
import { getDOMCls, getNoTrialTranslationKeyIfNeeded, Permalink, userLogo } from 'helpers';
import AppLink from '../AppLink/AppLink';
import Analytics from 'helpers/Analytics';
import { mobileMenuCls } from 'constants/ui';
import { useLang } from 'context/langContext';
import { useAuth } from 'context';

export const closeMenu = (): void => {
  const htmlClsList = getDOMCls();
  htmlClsList.remove(mobileMenuCls);
  const collapseCls = ['collapse-menu-opened', 'all-genres-opened'];
  const openElements = document.querySelectorAll(collapseCls.map((c) => `.${c}`).join(', '));
  openElements.forEach((el) => collapseCls.forEach((cls) => el.classList.remove(cls)));
};

export const getAuthorizedItems = (
  t: Translate,
  showPaidSubscriptionRelatedItems = true,
  countryCode: string,
): JSX.Element => {
  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await signout();
    window.location.href = '/';
  };

  const { locale } = useLang();
  const { signout, user, isAnonymous } = useAuth();

  if (!user && isAnonymous) {
    return (
      <>
        <li className={`unlogged login `}>
          <AppLink href={Permalink.login(locale)}>
            <a className="personal">{t('menu.login')}</a>
          </AppLink>
        </li>
        <li className={`unlogged try-free `}>
          <AppLink href={Permalink.registerStep1(locale)}>
            <a className="register">{t(getNoTrialTranslationKeyIfNeeded('menu.try_free', countryCode))}</a>
          </AppLink>
          <p className="login-prompt">
            {t('register:already_account')}{' '}
            <AppLink href={Permalink.login(locale)}>
              <a>{t('register:login_prompt')}</a>
            </AppLink>
          </p>
        </li>
      </>
    );
  } else if (user && !isAnonymous) {
    return (
      <>
        <li className="user-menu" id="user-menu-container">
          <div className="image-logo" id="image_logo_text">
            {userLogo(user?.full_name ?? user?.email)}
            {user?.showProfileWarning && <span className="warning" />}
            <strong>{user?.full_name}</strong>
          </div>
          <div className="content">
            <AppLink href={`/${RouteType.Profile}`}>
              <a>{t('menu.my_profile')}</a>
            </AppLink>
            {showPaidSubscriptionRelatedItems && (
              <>
                <AppLink href={Permalink.inviteFriends(locale)}>
                  <a>{t('menu.invite_friends')}</a>
                </AppLink>
                <AppLink href={Permalink.contentSettings(locale)}>
                  <a>{t('menu.content_settings')}</a>
                </AppLink>
              </>
            )}
            <a href="/#/logout" className="logout" onClick={handleLogout}>
              {t('menu.logout')}
            </a>
          </div>
          <div className="logo-arrow" />
        </li>
      </>
    );
  }
};
