import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useState, useEffect, createContext, useContext, ReactNode, ReactElement } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseApp } from '../api/firebase';
import { getSharedApiClient } from '../api/client';
import User from 'models/User';
import { LoadingPage, BrowseSkeleton } from 'components';
import { SkeletonType, Events, RouteType } from 'enums';
import { ProfileSkeleton } from 'components/ProfileSkeleton';
import { forceLanguage, isLoginRoute, Permalink } from 'helpers';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useLang } from './langContext';

let refreshTokenTimeout: number;

interface AuthContext {
  user: User;
  locale: string;
  setUser: (user: User) => void;
  signin: (email: string, password: string) => Promise<firebase.User>;
  signout: () => Promise<void>;
  signInWithCustomToken: (customToken: string) => Promise<boolean>;
  isAnonymous: boolean;
  reloadUser: () => Promise<User>;
  isUserLoaded: boolean;
  deleteUser: () => Promise<void>;
  handleVerifyEmail: (actionCode: string) => Promise<void>;
  resetPassword: (actionCode: string, password: string) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<boolean>;
  resendVerificationEmail: () => Promise<boolean>;
}

const authContext = createContext<AuthContext>({
  user: null,
  setUser: null,
  signin: null,
  signout: null,
  signInWithCustomToken: null,
  isAnonymous: false,
  reloadUser: null,
  isUserLoaded: false,
  deleteUser: null,
  handleVerifyEmail: null,
  resetPassword: null,
  changePassword: null,
  resendVerificationEmail: null,
  locale: null,
});

export function withAuth<CP, IP = CP>(
  WrappedComponent: NextPage<CP, IP>,
  route: string | { [index: string]: string } = RouteType.DefaultRedirect,
  skeletonType?: SkeletonType,
): NextPage<CP, IP> {
  const WithAuthRedirectWrapper: NextPage<CP, IP> = (props) => {
    const router = useRouter();
    const { locale } = useLang();
    const { user, isAnonymous, isUserLoaded } = useProvideAuth();

    let _route = route === RouteType.DefaultRedirect ? Permalink.login(locale) : route;
    if (typeof route !== 'string') {
      _route = route[locale];
    }

    if (!isUserLoaded) {
      if (skeletonType === SkeletonType.Browse) {
        return <BrowseSkeleton />;
      }
      if (skeletonType === SkeletonType.Profile) {
        return <ProfileSkeleton />;
      }

      return <LoadingPage />;
    } else if (!user && isAnonymous) {
      // trying hit secured page, but user is anonymous -> login
      router.push(_route || '/');
      return <LoadingPage />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  return WithAuthRedirectWrapper;
}
/**
 * Use when user only needs to see the page when not subscribed.
 *
 * @export
 * @template CP
 * @template IP
 * @param {NextPage<CP, IP>} WrappedComponent
 * @returns {NextPage<CP, IP>}
 */
export function withNoSubscription<CP, IP = CP>(WrappedComponent: NextPage<CP, IP>): NextPage<CP, IP> {
  const WithNoSubscriptionWrapper: NextPage<CP, IP> = (props) => {
    const router = useRouter();
    const { locale } = useLang();
    const { user } = useProvideAuth();

    if (user?.isSubscriber && !user?.needsToPay) {
      router.push(Permalink.root(user, locale));
      return <BrowseSkeleton />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  return WithNoSubscriptionWrapper;
}

/**
 * Force logged in user with isWeekOldWithoutSub sympt. to redirect to Choose plan page.
 *
 * @export
 * @template CP
 * @template IP
 * @param {NextPage<CP, IP>} WrappedComponent
 * @param {(string | { [index: string]: string })} [route=RouteType.ChoosePlan]
 * @param {SkeletonType} [skeletonType]
 * @return {*}  {NextPage<CP, IP>}
 */
export function withForcePlanToLoggedInUnsubs<CP, IP = CP>(
  WrappedComponent: NextPage<CP, IP>,
  route: string | { [index: string]: string } = RouteType.ChoosePlan,
  skeletonType?: SkeletonType,
): NextPage<CP, IP> {
  const WithPlanRedirectWrapper: NextPage<CP, IP> = (props) => {
    const router = useRouter();
    const { locale } = useLang();
    const { user, isUserLoaded } = useProvideAuth();
    let _route = route;
    if (typeof route !== 'string') {
      _route = route[locale];
    }

    if (!isUserLoaded) {
      if (skeletonType === SkeletonType.Browse) {
        return <BrowseSkeleton />;
      }
      if (skeletonType === SkeletonType.Profile) {
        return <ProfileSkeleton />;
      }

      return <WrappedComponent {...props} />;
    } else if (user && user.isWeekOldWithoutSub) {
      router.replace(`/${_route}`);
      return <LoadingPage />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  return WithPlanRedirectWrapper;
}

export function redirectWhenAuth<CP, IP = CP>(
  WrappedComponent: NextPage<CP, IP>,
  route: string | { [index: string]: string } = RouteType.Welcome,
): NextPage<CP, IP> {
  const WithAuthRedirectWrapper: NextPage<CP, IP> = (props) => {
    const router = useRouter();
    const { locale } = useLang();
    const { user, isAnonymous, isUserLoaded } = useProvideAuth();

    let _route = route;
    if (typeof route !== 'string') {
      _route = route[locale];
    }

    if (!isUserLoaded) {
      return <LoadingPage />;
    } else if (!isAnonymous && user) {
      // trying hit secured page, but user is anonymous -> login
      router.push(_route || '/');
      return <LoadingPage />;
    } else {
      if (isLoginRoute(router.asPath) && user) {
        return <LoadingPage />;
      }
      return <WrappedComponent {...props} />;
    }
  };

  return WithAuthRedirectWrapper;
}

export function ProvideAuth({ children }: { children: ReactNode }): ReactElement {
  const auth = useProvideAuth();
  return <authContext.Provider value={{ ...auth }}>{children}</authContext.Provider>;
}

export const useAuth = (): AuthContext => {
  return useContext(authContext);
};

function broadcastToken(token: string): void {
  setCookie(null, 'USER', token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });
  window.dispatchEvent(new CustomEvent(Events.AuthToken, { detail: token }));
}

function useProvideAuth() {
  const [user, setUser] = useState<User>(null);
  const [isAnonymous, setAnonymous] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const locale = (() => {
    const cookies = parseCookies();
    return user?.language ?? cookies.NEXT_LOCALE;
  })();

  const setUserAndToken = async (user: firebase.User) => {
    setIsUserLoaded(false);
    const idToken = await user.getIdToken();

    broadcastToken(idToken);

    if (idToken) {
      const cookies = parseCookies();
      const userResult = await getSharedApiClient().getUser();
      const userModel = User.fromJSON(userResult);
      setUser(userModel);
      setAnonymous(false);
      forceLanguage(userModel.language ?? cookies.NEXT_LOCALE);
      setIsUserLoaded(true);

      return userModel;
    }
  };

  const signin = async (email: string, password: string) =>
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        if (user && user.emailVerified) {
          setUserAndToken(user);
          setAnonymous(false);
        }
        return user;
      });

  const signInWithCustomToken = (customToken: string) =>
    firebaseApp
      .auth()
      .signInWithCustomToken(customToken)
      .then((userCredential: firebase.auth.UserCredential) => {
        if (userCredential.user.emailVerified) {
          setUserAndToken(userCredential.user);
          setAnonymous(false);
        }

        return true;
      })
      .catch(() => false);

  const signout = async () =>
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        broadcastToken(null);
        setAnonymous(true);
      });

  const reloadUser = async () => {
    if (firebaseApp.auth().currentUser) {
      await firebaseApp.auth().currentUser.reload();

      const currentUser = firebaseApp.auth().currentUser;
      if (currentUser?.emailVerified) {
        return await setUserAndToken(currentUser);
      }
    }
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    try {
      const signIn = await firebaseApp.auth().signInWithEmailAndPassword(user.email, currentPassword);

      if (signIn?.user) {
        await signIn.user.updatePassword(newPassword);

        return true;
      }

      return false;
    } catch {
      return false;
    }
  };

  /**
   * TODO: use dramox backend version
   */
  const resendVerificationEmail = async () => {
    try {
      const currentUser = firebaseApp.auth()?.currentUser;
      await currentUser?.sendEmailVerification();

      return true;
    } catch {
      return false;
    }
  };

  const resetPassword = async (actionCode: string, password: string) =>
    firebaseApp
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        firebaseApp
          .auth()
          .confirmPasswordReset(actionCode, password)
          .then(() => {
            firebaseApp
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((userCredential) => {
                if (userCredential.user.emailVerified) {
                  setUserAndToken(userCredential.user);
                  setAnonymous(false);
                }
                return Promise.resolve();
              });
          });
      })
      .catch(() => Promise.reject());

  const deleteUser = async () => {
    await firebaseApp.auth().currentUser.delete();
    destroyCookie(null, 'USER', { path: '/' });
    setAnonymous(true);
    setUser(null);
  };

  const handleVerifyEmail = async (actionCode: string): Promise<void> => {
    try {
      await firebaseApp.auth().applyActionCode(actionCode);
      const unsub = firebaseApp.auth().onAuthStateChanged(async (user) => {
        if (user) {
          await setUserAndToken(user);
          setAnonymous(false);
        }
        unsub();
        return Promise.resolve();
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        await setUserAndToken(user);
      } else {
        setUser(null);
        setAnonymous(true);
        setIsUserLoaded(true);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const refreshToken = () => {
    if (firebaseApp.auth().currentUser) {
      firebaseApp.auth().currentUser.getIdToken(true).then(broadcastToken);
    }
  };

  useEffect(() => {
    window.clearInterval(refreshTokenTimeout);
    // silently update id token each 20 minutes
    if (user) {
      refreshTokenTimeout = window.setInterval(refreshToken, 20 * 60 * 1000);
      document.onvisibilitychange = () => {
        if (!document.hidden) {
          refreshToken();
        }
      };
      window.onfocus = () => {
        refreshToken();
      };
    }
  }, [user]);

  // Return the user object and auth methods
  return {
    deleteUser,
    isUserLoaded,
    isAnonymous,
    reloadUser,
    user,
    setUser,
    signin,
    signout,
    signInWithCustomToken,
    handleVerifyEmail,
    resetPassword,
    changePassword,
    resendVerificationEmail,
    locale,
  };
}
