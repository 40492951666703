import { DRMLicenseType, DRMLicenseKey } from '../enums/drm';

/* class */

class PlaformHelper {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private get IS_MAC() {
    return navigator.platform.indexOf('Mac') > -1;
  }
  private get IS_WINDOWS() {
    return navigator.platform.indexOf('Win') > -1;
  }
  private get USER_AGENT() {
    return (window.navigator && window.navigator.userAgent) || '';
  }
  private get IE_VERSION() {
    return (function () {
      const result = /MSIE\s(\d+)\.\d/.exec(this.USER_AGENT);
      let version = result && parseFloat(result[1]);

      if (!version && /Trident\/7.0/i.test(this.USER_AGENT) && /rv:11.0/.test(this.USER_AGENT)) {
        version = 11.0;
      }

      return version;
    })();
  }
  private get IS_EDGE() {
    return this.USER_AGENT.indexOf('Edge/') > -1;
  }
  private get IS_CHROMIUM_EDGE() {
    return this.USER_AGENT.indexOf('Edg/') > -1;
  }
  private get IS_ANDROID() {
    return /Android/i.test(this.USER_AGENT);
  }
  private get IS_CHROME() {
    return (
      !this.IS_EDGE && !this.IS_CHROMIUM_EDGE && (/Chrome/i.test(this.USER_AGENT) || /CriOS/i.test(this.USER_AGENT))
    );
  }
  private get IS_SAFARI() {
    return (
      /Safari/i.test(this.USER_AGENT) && !this.IS_CHROME && !this.IS_ANDROID && !this.IS_EDGE && !this.IS_CHROMIUM_EDGE
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private get IS_IE() {
    return this.IS_EDGE || this.IE_VERSION;
  }
  private get TOUCH_ENABLED() {
    return Boolean(
      'ontouchstart' in window ||
        window.navigator.maxTouchPoints ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (window.DocumentTouch && window.document instanceof window.DocumentTouch),
    );
  }
  private get IS_IPOD() {
    return /iPod/i.test(this.USER_AGENT);
  }
  private get IS_IPAD() {
    return /iPad/i.test(this.USER_AGENT) || (this.IS_SAFARI && this.TOUCH_ENABLED && !/iPhone/i.test(this.USER_AGENT));
  }
  private get IS_IPHONE() {
    return /iPhone/i.test(this.USER_AGENT) && !this.IS_IPAD;
  }
  private get IS_IOS() {
    return this.IS_IPHONE || this.IS_IPAD || this.IS_IPOD;
  }
  private get IS_APPLE() {
    return this.IS_IOS || this.IS_SAFARI;
  }

  private isDevelopment = process.env.NODE_ENV !== 'production';

  get isProduction(): boolean {
    return !this.isDevelopment;
  }

  get platform(): string {
    return process.env.PLATFORM ?? 'web';
  }

  get drmLicenseServerUrl(): string {
    if (this.drmLicenseType === DRMLicenseType.Widevine) {
      return process.env.NEXT_PUBLIC_DRM_LICENSE_URL_WIDEVINE;
    }

    return process.env.NEXT_PUBLIC_DRM_LICENSE_URL_PLAYREADY;
  }

  get drmLicenseType(): DRMLicenseType {
    // use Google's Widevine license as a default
    let license = DRMLicenseType.Widevine;
    if (this.IS_SAFARI || this.IS_IOS) {
      license = DRMLicenseType.FairPlay;
    } else if (this.IS_EDGE && this.IS_WINDOWS) {
      // Only switch to PlayReady on Windows with non-Chromium based Edge
      license = DRMLicenseType.PlayReady;
    }

    return license;
  }

  get drmLicenseTypeKey() {
    return DRMLicenseKey[this.drmLicenseType];
  }

  get isApple(): boolean {
    return this.IS_APPLE;
  }

  get isChrome(): boolean {
    return this.IS_CHROME;
  }

  get isSafari(): boolean {
    return this.IS_SAFARI;
  }

  get isMobile(): boolean {
    return this.IS_IOS || this.IS_ANDROID;
  }

  get isMobileSafari(): boolean {
    return this.IS_IOS && this.IS_SAFARI;
  }
}

export default new PlaformHelper();
