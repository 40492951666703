import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { LANGUAGES_OPTIONS, mobileMenuCls } from '../../constants';
import {
  debounce,
  forceLanguage,
  getDOM,
  getDOMCls,
  getNoTrialTranslationKeyIfNeeded,
  Permalink,
  sortAlphabetically,
} from '../../helpers';
import { Namespace } from '../../enums';
import { GenreBase } from '../../typings/api';
import { useAuth } from '../../context/authContext';
import AppLink from '../AppLink/AppLink';
import { useLang } from 'context/langContext';
import PlatformHelper from 'helpers/PlatformHelper';
import { InfoToolbar } from '..';
import { getSharedApiClient } from '../../api/client';
import Select from '../Select/Select';
import { Country } from '../../enums/country';
import Analytics from 'helpers/Analytics';

interface Props {
  countryCode: string;
}

const BareHeader = ({ countryCode }: Props): JSX.Element => {
  const headerRef = React.useRef<HTMLElement>(null);
  const navigationRef = React.useRef<HTMLElement>(null);
  const [mainGenres, setMainGenres] = React.useState<GenreBase[]>([]);
  const [didScroll, setDidScroll] = React.useState<boolean>(false);
  const { t } = useTranslation(Namespace.Common);
  const { user, isUserLoaded } = useAuth();
  const { locale } = useLang();
  const [genres, setGenres] = useState<GenreBase[]>();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const getLangFlag = useCallback(() => LANGUAGES_OPTIONS.find((item) => item.value === locale).flag, [locale]);

  useEffect(() => {
    getSharedApiClient().getGenres().then(setGenres);
  }, [locale]);

  const onLanguageChange = useMemo(
    () => async (language: string) => {
      if (!user) {
        forceLanguage(language);
      } else {
        await getSharedApiClient().updateUserData({
          language,
        });
      }
      window.location.reload();
    },
    [user, isUserLoaded],
  );

  useEffect(() => {
    setMainGenres(genres?.filter((g) => ['drama', 'comedy', 'tragedy'].indexOf(g.slug) > -1) ?? []);
  }, [genres]);

  const toggleMobileMenu = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setMobileMenuOpen((state) => !state);
  };

  useEffect(() => {
    const htmlClsList = getDOMCls();
    if (!htmlClsList.contains(mobileMenuCls)) {
      if (PlatformHelper.isMobileSafari) {
        window.scroll(0, 1);
      }
      htmlClsList.add(mobileMenuCls);
    } else {
      htmlClsList.remove(mobileMenuCls);
    }
  }, [mobileMenuOpen]);

  const onCollapseMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if (mobileMenuOpen) {
      e.stopPropagation();
      e.preventDefault();

      const parent = (e.target as HTMLAnchorElement).parentElement as HTMLLIElement;
      const collapseCls = 'collapse-menu-opened';

      if (parent.classList.contains(collapseCls)) {
        parent.classList.remove(collapseCls);
      } else {
        parent.classList.add(collapseCls);
      }
      const allGenres = getDOM().querySelector('.all-genres-opened');
      if (allGenres && parent.classList.contains('genres')) {
        allGenres.classList.remove('all-genres-opened');
      }
    }
  };

  const handleScroll = () => {
    const header = headerRef?.current;

    if (header) {
      const navElementHeight = header?.clientHeight || null;

      window.scrollY > navElementHeight ? setDidScroll(true) : setDidScroll(false);
    } else {
      setDidScroll(window.scrollY > 64);
    }
  };

  React.useEffect(() => {
    const listenerCallback = debounce(handleScroll);

    if (window) {
      window.addEventListener('scroll', listenerCallback, false);

      return () => {
        window.removeEventListener('scroll', listenerCallback, false);
      };
    }
  }, [debounce, handleScroll]);

  React.useEffect(() => {
    const htmlClsList = getDOMCls();
    if (htmlClsList.contains(mobileMenuCls)) {
      htmlClsList.remove(mobileMenuCls);
    }

    return () => {
      htmlClsList.remove(mobileMenuCls);
    };
  }, []);

  const sortedGenres = sortAlphabetically(genres, 'name');

  /*
    Hide core menu items when user is registered but still hasn't activated subscription within the first week (typically /choose-plan after first login).
    Show core menu items in all other cases.
  */
  const showCoreMenuEntries = useMemo(() => {
    return !user || !user.isWeekOldWithoutSub;
  }, [user]);

  return (
    <header className={classNames('fixed-top', { fixed: didScroll, blendLogo: countryCode !== 'pl' })} ref={headerRef}>
      {/* {countryCode === Country.Ukraine && (
        <InfoToolbar translationKey="ua-support:title" link={Permalink.uaSupport(locale)} />
      )} */}
      <div className="header-content">
        <div>
          <a href="/#/toggle-nav" className="nav-toggle" id="nav-toggle" onClick={toggleMobileMenu}>
            <span className="toggle-lang">{getLangFlag()}</span>
          </a>
          {showCoreMenuEntries && <label htmlFor="input-search" className="search" />}
          <a href={Permalink.registerStep1(locale)} className={'btn-register-header'}>
            {t('common:menu.try_now')}
          </a>
          <nav className={classNames('menu', user && 'logged-in')} role="main" ref={navigationRef}>
            <ul>
              <li className="menu-item with-icon gift-vouchers" title={t('menu.voucher')}>
                <AppLink href={Permalink.voucher(locale)}>
                  <a>{t('menu.voucher')}</a>
                </AppLink>
              </li>
              <li className="menu-item with-icon set-language" style={{ marginRight: 30 }}>
                <div className="lang-flag">{getLangFlag()}</div>
                <div className="language-list">
                  {LANGUAGES_OPTIONS.map((val, i) => (
                    <a
                      key={i}
                      onClick={() => {
                        onLanguageChange(val.value);
                      }}
                      dangerouslySetInnerHTML={{ __html: val.label }}
                    ></a>
                  ))}
                </div>
              </li>
              <li className={`unlogged try-free `}>
                <AppLink href={Permalink.registerStep1(locale)}>
                  <a className="register">
                    {t(getNoTrialTranslationKeyIfNeeded(`welcome:welcome_button_long_${countryCode}`, countryCode))}
                  </a>
                </AppLink>
                <p className="login-prompt">
                  {t('register:already_account')}{' '}
                  <AppLink href={Permalink.login(locale)}>
                    <a>{t('register:login_prompt')}</a>
                  </AppLink>
                </p>
              </li>
              <li>
                <div className="languages">
                  <label htmlFor="language"></label>
                  <span>{t('profile:ui_language')}</span>
                  {isUserLoaded && (
                    <Select
                      name="language"
                      options={LANGUAGES_OPTIONS}
                      defaultValue={locale}
                      className="tiny"
                      onChange={(lang) => onLanguageChange(lang)}
                    />
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <span
          className={['logo', locale === 'uk' ? 'small-logo' : ''].join(' ')}
          title="Dramox"
          mobile-text={t('common:menu.logo_mobile')}
        >
          Dramox
        </span>
      </div>
    </header>
  );
};

export default BareHeader;
