import DramoxImage from 'components/DramoxImage';
import { twoLetterShortName } from 'helpers';
import { CelebrityBase } from '../typings/api';

export const getCastImg = (cast: CelebrityBase): JSX.Element => {
  if ('poster_url' in cast) {
    return <DramoxImage className="image" src={cast.poster_url} alt={cast.name} width={36} height={36} quality={90} />;
  }

  return <span className="image">{twoLetterShortName(cast.name)}</span>;
};
