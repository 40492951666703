import { createNotificationContext } from 'react-notification-provider';
import { v4 as uuidv4 } from 'uuid';

// You can customize the notification interface to include whatever props your notifications need to render.
export interface NotificationData {
  title: string;
  message?: string;
  duration?: number;
  level?: 'success' | 'danger' | 'info';
}

// This function creates a React context and hooks for you so you'll want to export these.
const { NotificationProvider, useNotificationQueue } = createNotificationContext<NotificationData>();

export { NotificationProvider as NotificationContext, useNotificationQueue };

/**
 * Shorthand use of useNotificationQueue
 * provides uuid for the useNotificationQueue.add() function.
 */
export const useNotificationContext = (): { createNotification: (data: NotificationData) => void } => {
  const { add, removeAll } = useNotificationQueue();

  const createNotification = (data: NotificationData) => {
    removeAll();
    add(uuidv4(), data);
  };

  return {
    createNotification,
  };
};
