import { format } from 'date-fns';
import { enGB, cs } from 'date-fns/locale';

export const formatDate = (date: string | Date, locale: string): string =>
  date
    ? format(new Date(date), 'd. M. yyyy', {
        locale: locale === 'cs' ? cs : enGB,
      })
    : '';

export const formatYear = (date: string | Date, locale: string): string =>
  date
    ? format(new Date(date), 'yyyy', {
        locale: locale === 'cs' ? cs : enGB,
      })
    : '';

/**
 * Formats seconds to a human readable HH:MM:SS string.
 *
 * @export
 * @param {number} seconds
 * @returns {string}
 */
export function sec2toHHMMSS(seconds: number): string {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
}

// https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
export function sec2time(val: number): string {
  const sec_num = parseInt(val?.toString(), 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  if (seconds && !minutes && !hours) {
    return '<1m';
  }

  let result = '';
  if (minutes) {
    result = `${minutes}m`;
  }

  if (hours) {
    result = `${hours}h ${result}`;
  }

  return result;
}
