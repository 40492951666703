import styles from './index.module.scss';

interface Props {
  spinnerSize?: 'small' | 'medium';
}

const LoadingComponent = ({ spinnerSize = 'small' }: Props): JSX.Element => {
  const classes = [styles.loading];
  if (spinnerSize === 'medium') {
    classes.push(styles.medium);
  }
  return <div className={classes.join(' ')}></div>;
};

export default LoadingComponent;
