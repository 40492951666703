/* tslint:disable */
import { Plan } from './plan';
import { SubscriptionType } from './subscription-type';

export enum UserPromoType {
  USER_INVITATION = 'USER_INVITATION',
  CAMPAIGN = 'CAMPAIGN',
}

export interface User {
  id?: number;
  email?: string;
  full_name?: string;
  address?: string;
  subscribed_to_newsletter?: boolean;
  first_name?: string;
  last_name?: string;
  birthday?: string;
  payment_method?: 'STRIPE' | 'BANK_ACCOUNT' | 'NONE';
  subscription_type?: SubscriptionType;
  subscription_expiration?: string;
  auto_renew?: boolean;
  plan?: Plan;
  components: { [index: string]: number };
  had_subscription: boolean;
  language?: string;
  subscription_purchased_till?: string;
  auto_renew_failed_attempts?: number;
  auto_renew_remaining_attempts?: number;
  content_languages: string[];
  promo_code?: UserPromoType;
}
