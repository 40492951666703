import { useLang } from '../../context/langContext';
import Layout from '../Layout';
import LoadingComponent from '../LoadingComponent';
import styles from './index.module.scss';

export const LoadingPage = (): JSX.Element => {
  const { locale } = useLang();
  return (
    <Layout countryCode={locale}>
      <main className={styles.loading}>
        <LoadingComponent spinnerSize={'medium'} />
      </main>
    </Layout>
  );
};
