import routeTable from 'config/routeTable';
import { Country } from 'enums/country';

export const isLiveProduction = !!+process.env.NEXT_PUBLIC_IS_LIVE_PRODUCTION;
export const isDevelopment = process.env.NODE_ENV !== 'production';
export const isProduction = !isDevelopment;

const localeCountryMap = {
  cs: Country.Czechia,
  sk: Country.Slovakia,
  uk: Country.Ukraine,
  pl: Country.Poland,
};

export function isIPWhitelistedForSkippingIPCheck(ip: string): boolean {
  // only whitelist for dev
  if (isLiveProduction) {
    return false;
  }

  const whitelist = `34.199.54.x
  34.232.25.x
  34.232.119.x
  34.236.25.x
  35.171.175.x
  52.54.90.x
  52.202.195.x
  52.203.14.x
  52.204.96.x
  34.218.156.x
  34.218.168.x
  52.41.219.x
  35.155.178.x
  35.160.177.x
  34.216.18.x`;

  const whitelistIps = whitelist.split('\n').map((ip) => ip.replace('.x', ''));

  return whitelistIps.filter((_ip) => ip.indexOf(_ip) !== -1).length > 0;
}

export function isGoogleUserAgent(agent: string): boolean {
  return (agent ?? '').search(/(G|g)oogle/g) > -1;
}

let _host: string;
let _lang: string;

export function setHost(host: string): void {
  _host = host;
}

export function host(): string {
  if (process.browser) {
    return window.location.host;
  }

  return _host;
}

export function getServerSideLang(): string {
  return _lang;
}

export function localeToCountry(locale: string): string {
  return localeCountryMap[locale];
}

/**
 * Some countries don't have trial. Return alternative translation key for the original key
 *
 * @export
 * @param {string} originalKey
 * @param {string} countryLocale
 * @returns
 */
export function getNoTrialTranslationKeyIfNeeded(originalKey: string, countryCode: string) {
  if (countryHasNoTrial(countryCode)) {
    let namespace = '';
    let key = '';
    const namespaceSplit = originalKey.split(':');
    if (namespaceSplit.length > 1) {
      namespace = namespaceSplit[0];
    }
    key = namespaceSplit[namespaceSplit.length - 1];
    const context = key.split('.');
    context[context.length - 1] = `no_trial_${context[context.length - 1]}`;
    return [namespace, context.join('.')].filter((t) => !!t).join(':');
  }

  return originalKey;
}

export function countryHasNoTrial(country: string) {
  return country === 'pl';
}

export function countryHasTrial(country: string) {
  return !countryHasNoTrial(country);
}
