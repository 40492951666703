export function shuffleArray<T>(t: T[]): T[] {
  return Array.from(sample<T>(t, t.length));
}

function* sample<T>(t: T[], n: number) {
  const r = Array.from(t);
  while (n > 0 && r.length) {
    const i = rand(r.length); // 1
    swap(r, i, r.length - 1); // 2
    yield r.pop(); // 3
    n = n - 1;
  }
}

const rand = (n) => Math.floor(Math.random() * n);

function swap(t, i, j) {
  const q = t[i];
  t[i] = t[j];
  t[j] = q;
  return t;
}
