import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotificationQueue } from '../context';
import styles from './notificationList.module.scss';
import classNames from 'classnames';

interface NotificationProps {
  id: string;
  variant: 'success' | 'danger' | 'info';
  duration: number;
  title: string;
  message?: string;
}

const Notification: React.FC<NotificationProps> = (props) => {
  const { id, duration = 2500, title, message, variant = 'success' } = props;
  const notification = useNotificationQueue();

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      notification.remove(id);
    }, duration);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const getVariantClassName = () => {
    switch (variant) {
      case 'success':
        return styles.notificationSuccess;
      case 'info':
        return styles.notificationInfo;
      case 'danger':
        return styles.notificationDanger;

      default:
        throw new Error(`Undefined case '${variant}' of variant type in Notification.getVariantClassName().`);
    }
  };

  return (
    <div
      role="alert"
      key={id}
      className={classNames(styles.notification, getVariantClassName())}
      onClick={() => {
        notification.remove(id);
      }}
    >
      <strong className="d-block">{title}</strong>
      {message && <p className="mb-0">{message}</p>}
    </div>
  );
};

export const NotificationList: React.FC = () => {
  const queue = useNotificationQueue();

  return (
    <div className={styles.notificationList}>
      <AnimatePresence initial={false}>
        {queue.entries.map(({ id, data }) => (
          <motion.div key={id} initial={{ opacity: 0, y: 50, scale: 0.3 }} animate={{ opacity: 1, y: 0, scale: 1 }}>
            <Notification
              id={id}
              variant={data.level}
              title={data.title}
              message={data.message}
              duration={data.duration}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};
