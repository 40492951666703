
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import '../styles/main.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { ProvideAuth, ProvidePopup, NotificationContext } from 'context';
import Analytics from 'helpers/Analytics';
import { AppProps } from 'next/app';

function Dramox({ Component, pageProps }: AppProps): JSX.Element {
  const [history, setHistory] = useState([]);
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    setHistory([asPath]);

    // since we use SPA and hard history pushes don't occur, update current page's url for every virtual page change
    router.events.on('beforeHistoryChange', (route) => Analytics.setCurrentUrlForGoogle(route));

    // hook app's version on window
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._version = {
      version: process.env.NEXT_PUBLIC_VERSION,
      commit: process.env.NEXT_PUBLIC_COMMIT_HASH,
      environment: process.env.NODE_ENV,
      domain: process.env.NEXT_PUBLIC_HOST_EN,
    };
  }, []);

  useEffect(() => {
    if (history[history.length - 1] !== asPath) {
      setHistory((prevState) => [...prevState, asPath]);
    }
  }, [asPath]);

  return (
    <ProvideAuth>
      <ProvidePopup>
        <NotificationContext>
          <Component history={history} {...pageProps} />
        </NotificationContext>
      </ProvidePopup>
    </ProvideAuth>
  );
}

const __Page_Next_Translate__ = Dramox;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  