import firebase from 'firebase/app';

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

export const firebaseApp = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

// /**
//  * Given a refresh token, get a new Firebase ID token. Call this when
//  * the Firebase ID token has expired.
//  * @return {String} The new ID token
//  */
// export const refreshExpiredIdToken = async (refreshToken: string) => {
//   if (!refreshToken) {
//     throw new Error('The "refreshToken" argument is required.');
//   }

//   const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;

//   // https://firebase.google.com/docs/reference/rest/auth/#section-refresh-token
//   const endpoint = `https://securetoken.googleapis.com/v1/token?key=${apiKey}`;
//   console.log("refreshing from here");
//   const response = await fetch(endpoint, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
//     body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
//   });
//   const responseJSON = await response.json();
//   if (!response.ok) {
//     throw new Error(`Problem refreshing token: ${JSON.stringify(responseJSON)}`);
//   }
//   const idToken = responseJSON.id_token;
//   return idToken;
// };
