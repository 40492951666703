import useTranslation from 'next-translate/useTranslation';
import { Permalink } from 'helpers';
import AppLink from '../AppLink/AppLink';
import { Namespace } from 'enums/namespaces';
import { useAuth } from 'context/authContext';
import { useLang } from 'context/langContext';
import { Socials } from '../../typings/api/socials';
import { Country } from 'enums/country';
import { useEffect, useState } from 'react';
import { getSharedApiClient } from 'api/client';

interface Props {
  countryCode: string;
}

const Footer = ({ countryCode }: Props): JSX.Element => {
  const { user, isUserLoaded } = useAuth();
  const { locale } = useLang();
  const { t } = useTranslation(Namespace.Profile);
  const [socials, setSocials] = useState<Socials[]>();

  useEffect(() => {
    if (isUserLoaded) {
      getSharedApiClient().getSocials().then(setSocials);
    }
  }, [isUserLoaded]);

  const FOOTER_PAGES = () => [
    {
      href: Permalink.about(locale),
      label: t('common:menu.about_project'),
    },
    {
      href: Permalink.donate(locale),
      label: t('common:menu.theatre'),
    },
    {
      href: Permalink.forSchools(locale),
      label: t('common:menu.for_schools'),
    },
    {
      href: Permalink.howToWatch(locale),
      label: t('common:menu.how_to_watch'),
    },
    {
      href: Permalink.contact(locale),
      label: t('common:menu.contact'),
    },
    {
      href: Permalink.faq(locale),
      label: t('common:menu.faq'),
    },
  ];

  return (
    <footer>
      <AppLink href={Permalink.root(user, locale)}>
        <a className="logo" title="Dramox">
          Dramox
        </a>
      </AppLink>
      {!user?.isWeekOldWithoutSub && (
        <ul>
          {FOOTER_PAGES().map((link, i) => (
            <li key={i}>
              <AppLink href={link.href}>
                <a>{link.label}</a>
              </AppLink>
            </li>
          ))}
        </ul>
      )}

      {socials && socials.length > 0 && (
        <ul className="socials">
          {socials.map((social) => (
            <li key={social.id} className={social.type.toLowerCase()}>
              <a target="_blank" href={social.url}></a>
            </li>
          ))}
        </ul>
      )}

      <p>
        <AppLink href={Permalink.termsAndConditions(locale)}>
          <a>{t('common:menu.terms_conditions')}</a>
        </AppLink>{' '}
        •{' '}
        <AppLink href={Permalink.privacyPolicy(locale)}>
          <a>{t('common:menu.privacy_policy')}</a>
        </AppLink>{' '}
        •{' '}
        {countryCode !== Country.Ukraine && (
          <>
            <AppLink href={Permalink.cookie()}>
              <a>{t('common:menu.cookies')}</a>
            </AppLink>
            {' •'}
          </>
        )}{' '}
        <AppLink href={Permalink.authorship(locale)}>
          <a>{t('common:menu.authorship')}</a>
        </AppLink>
        {countryCode === Country.Czechia && (
          <>
            {' • '}
            <AppLink href={Permalink.broadcast(locale)}>
              <a>{t('common:menu.broadcast')}</a>
            </AppLink>
          </>
        )}
      </p>
      <p>{t('common:ownership')}</p>
      <p>Copyright &copy; 2020-{new Date().getFullYear()} Dramox</p>
    </footer>
  );
};

export default Footer;
