import { baseUrl } from 'config/routeTable';
import { useRouter } from 'next/router';
import React from 'react';
import { truncateSeoDescription } from './common';

/**
 * Returns meta tags for given title, description and image.
 *
 * @export
 * @param {string} title
 * @param {string} description
 * @param {string} imageUrl
 * @returns {JSX.Element}
 */
export function basicSEO(
  title: string,
  description: string,
  imageUrl?: string,
  tagTitle?: string,
  truncateDescription = true,
): JSX.Element {
  const _description = truncateDescription ? truncateSeoDescription(description) : description;
  const { locale } = useRouter();

  const _imageUrl = imageUrl ?? `${baseUrl(locale)}/jpg/og-image.jpg`;

  return (
    <>
      <title>{tagTitle ?? title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={_description} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={_description} />
      <meta name="description" content={_description} />
      <meta property="og:image" content={_imageUrl} />
      <meta property="twitter:image" content={_imageUrl} />

      {/* <meta name="keywords" content={t('seo:theatres_keywords')} /> */}
    </>
  );
}
