import { getSharedApiClient } from 'api/client';
import AppLink from 'components/AppLink/AppLink';
import { useLang } from 'context/langContext';
import { Namespace } from 'enums/namespaces';
import { Permalink } from 'helpers';
import Analytics from 'helpers/Analytics';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { SearchResponse, SearchResponseItem } from 'typings/api';
import { mobileMenuCls } from '../../constants';

const MIN_SEARCH_INPUT = 3;
let debounceTimer: number = null;
const searchingCls = 'searching';

const SearchInput = (): JSX.Element => {
  const { t } = useTranslation(Namespace.Common);
  const [results, setResults] = useState<SearchResponse>(null);
  const [flattenedResults, setFlattenedResults] = useState<SearchResponseItem[]>([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);
  const router = useRouter();
  const { locale } = useLang();

  const refInput = useRef<HTMLInputElement>(null);
  const refResultList = useRef<HTMLDivElement>(null);

  const { plays, theatres, celebrities } = results ?? {
    plays: [],
    theatres: [],
    celebrities: [],
  };

  const close = () => {
    const docHtml = document.documentElement;
    const htmlClsList = docHtml.classList;
    htmlClsList.remove(searchingCls);
    setDisplaySearch(false);
    setResults(null);
    setFlattenedResults([]);

    setTimeout(() => {
      if (refInput.current) {
        refInput.current.value = '';
        refInput.current.blur();
      }
    }, 251); // close animation lasts for 250ms, set value to blank after it's finished
  };

  const open = () => {
    const docHtml = document.documentElement;
    const htmlClsList = docHtml.classList;
    htmlClsList.add(searchingCls);
    htmlClsList.remove(mobileMenuCls);
    setDisplaySearch(true);
  };

  useEffect(() => {
    if (results) {
      setFlattenedResults([...results.plays, ...results.theatres, ...results.celebrities]);
    } else {
      setFlattenedResults([]);
    }
  }, [results]);

  useEffect(() => {
    refInput.current.addEventListener('focus', function () {
      open();
    });

    refInput.current.addEventListener('keydown', function (e: KeyboardEvent) {
      if (e.code === 'Escape') {
        close();
      } else if (e.code === 'ArrowDown') {
        e.preventDefault();
        setActiveResultIndex((prev) => prev + 1);
      } else if (e.code === 'ArrowUp') {
        e.preventDefault();
        setActiveResultIndex((prev) => Math.max(0, prev - 1));
      } else if (e.code === 'Enter') {
        setEnterPressed(true);
      }
    });

    refInput.current.addEventListener('input', async function () {
      setActiveResultIndex(-1);
      window.clearTimeout(debounceTimer);
      const searchString = refInput.current.value;
      if (searchString.length >= MIN_SEARCH_INPUT) {
        // resolve after 200ms (or clear within next key stroke)
        debounceTimer = window.setTimeout(async () => {
          // fetch server results
          try {
            const results = await getSharedApiClient().search(searchString);
            setResults(results);
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }, 200);
      } else {
        setResults(null);
      }
    });

    // blur search on close button click
    const elSearchClose = document.getElementById('search-close');
    elSearchClose?.addEventListener('click', (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      (document.activeElement as HTMLInputElement)?.blur();
    });

    const elCloseSearch = document.getElementById('search-cancel');
    const elOverlay = document.getElementById('search-overlay');
    ['touchstart', 'click'].forEach((event) => {
      [elCloseSearch, elOverlay].forEach((el) => {
        el?.addEventListener(event, function (e: Event) {
          e.preventDefault();
          close();
        });
      });
    });
  }, [refInput]);

  useEffect(() => {
    if (refResultList && refResultList.current && document.documentElement.classList.contains('mobile')) {
      if (window.visualViewport) {
        refResultList.current.style.maxHeight = `${window.visualViewport.height - 15 - 60}px`;
      }
    }
  }, [refResultList.current]);

  useEffect(() => {
    if (activeResultIndex > flattenedResults.length - 1) {
      setActiveResultIndex(flattenedResults.length - 1);
    }
  }, [flattenedResults, activeResultIndex]);

  useEffect(() => {
    if (enterPressed && flattenedResults.length && flattenedResults[activeResultIndex]) {
      const url = refResultList.current.querySelectorAll('a')[activeResultIndex].href;
      router.push(url).then(close);
    }
  }, [enterPressed, activeResultIndex, flattenedResults, refResultList]);

  const emptySearch = displaySearch && !flattenedResults?.length && refInput.current?.value.length >= MIN_SEARCH_INPUT;
  const displayResultList = !emptySearch && displaySearch && results;

  return (
    <>
      <input type="text" placeholder={t('search_placeholder')} id="input-search" ref={refInput} autoComplete="off" />
      <label htmlFor="input-search" className="search">
        {t('search_placeholder')}
      </label>
      <div className="ico"></div>
      <span className="cancel" id="search-cancel">
        X
      </span>
      <div className="overlay" id="search-overlay"></div>
      {displayResultList ? (
        <div className="result-list" ref={refResultList}>
          {plays?.length > 0 && (
            <>
              <h3>{t('performances')}</h3>
              <ul>
                {plays.map((play) => (
                  <li key={play.id}>
                    <AppLink
                      href={Permalink.playFromSearchResponse(play, locale)}
                      onClick={() => {
                        close();
                      }}
                    >
                      <a
                        className={
                          flattenedResults[activeResultIndex]?.name === play.name &&
                          flattenedResults[activeResultIndex]?.id === play.id
                            ? 'active'
                            : null
                        }
                      >
                        <strong>{play.name}</strong>
                        <em>{play.theatre_name}</em>
                      </a>
                    </AppLink>
                  </li>
                ))}
              </ul>
            </>
          )}
          {theatres?.length > 0 && (
            <>
              <h3>{t('theatres')}</h3>
              <ul>
                {theatres.map((theatre) => (
                  <li key={theatre.name}>
                    <AppLink
                      href={Permalink.theatre(theatre, locale)}
                      onClick={() => {
                        close();
                      }}
                    >
                      <a
                        className={
                          flattenedResults[activeResultIndex]?.name === theatre.name &&
                          flattenedResults[activeResultIndex]?.id === theatre.id
                            ? 'active'
                            : null
                        }
                      >
                        <strong>{theatre.name}</strong>
                      </a>
                    </AppLink>
                  </li>
                ))}
              </ul>
            </>
          )}
          {celebrities?.length > 0 && (
            <>
              <h3>{t('actors')}</h3>
              <ul>
                {celebrities.map((celebrity) => (
                  <li key={celebrity.name}>
                    <AppLink href={Permalink.celebrity(celebrity, locale)} onClick={close}>
                      <a
                        className={
                          flattenedResults[activeResultIndex]?.id === celebrity.id &&
                          flattenedResults[activeResultIndex]?.name === celebrity.name
                            ? 'active'
                            : null
                        }
                      >
                        <strong>{celebrity.name}</strong>
                      </a>
                    </AppLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ) : emptySearch ? (
        <div className="result-list empty">
          <ul>
            <li>
              <strong>{t('no_search_results')}</strong>
            </li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default SearchInput;
