// import { useLang } from 'context/langContext';
import { useLang } from 'context/langContext';
import { localiseUrl } from 'helpers';
import Link, { LinkProps } from 'next/link';
import React, { cloneElement } from 'react';

interface Props extends LinkProps {
  children: JSX.Element | JSX.Element;
  onClick?: (e?: React.MouseEvent) => void;
}

const AppLink = ({ children, href, onClick, ...props }: Props): JSX.Element => {
  const { locale } = useLang();
  let localisedHref = href;
  if (typeof href === 'string') {
    localisedHref = localiseUrl(href, locale);
  }

  if (onClick) {
    // wrap in additional function in case we need to do something in the future (i.e. preventDefault etc.)
    const _onClick = () => {
      // e.preventDefault();
      onClick();
    };
    children = cloneElement(children, { onClick: _onClick });
  }

  return (
    <Link href={localisedHref} {...props} locale={false}>
      {children}
    </Link>
  );
};

export default AppLink;
