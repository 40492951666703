import PlatformHelper from 'helpers/PlatformHelper';
import { MutableRefObject, useEffect } from 'react';
import Swiper from 'swiper';

export function useSwiper(
  ref: MutableRefObject<HTMLDivElement>,
  progressBarRef: MutableRefObject<HTMLDivElement>,
): void {
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (ref) {
      const progressCls = 'animate';
      const progress = progressBarRef;
      const slideChange = () => {
        if (progress.current) {
          progress.current.classList.remove(progressCls);
        }
        timeout = setTimeout(() => {
          if (progress.current) {
            progress.current.classList.add(progressCls);
          }
        }, 900);
      };
      new Swiper(ref.current, {
        direction: 'horizontal',
        effect: PlatformHelper.isMobile ? 'slide' : 'fade',
        touchStartPreventDefault: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        loop: true,
        on: {
          slideChange,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (_, className) => {
            return `
              <div class="${className}">
                <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <circle r="16" cx="16" cy="16" stroke="white" fill="transparent" stroke-width="32" />
                </svg>
              </div>
            `;
          },
        },
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        keyboard: {
          enabled: false,
        },
      });
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [ref, progressBarRef]);
}
