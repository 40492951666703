export enum PopupType {
  SetPrimaryCard = 'changeChard',
  DeleteCard = 'deleteCard',
  DeleteUser = 'deleteUser',
  RemoveSubscription = 'removeSubscription',
  BuySubscription = 'buySubscription',
  NewsletterSubscription = 'NewsletterSubscription',
  Login = 'Login',
  LowQuality = 'LowQuality',
  PromoDiscount = 'PromoDiscount',
  Cookies = 'Cookies',
  CookiesBar = 'CookiesBar',
}
