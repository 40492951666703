const trackingScripts = (country: string): JSX.Element => (
  <>
    {/* startquestion.com */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          !function(e,t,n,o,a){var c=e.Startquestion=e.Startquestion||{};c.invoked?e.console&&console.warn&&console.warn("Startquestion snippet included twice."):(c.invoked=!0,c.queue=[],c.call=function(){c.queue.push(Array.prototype.slice.call(arguments))},(e=t.createElement("script")).type="text/javascript",e.async=!0,e.src=n,(t=t.getElementsByTagName("script")[0]).parentNode.insertBefore(e,t),c.call({type:"load",config:{key:o,lang:a}}))}(window,document,'https://library.startquestion.com/current/startquestion.js','f166e34e-40ec-11ec-8d09-00505685cca8','en');
          `,
      }}
    />
    {/* Google Tag Manager */}
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-M27M94C');
          `,
      }}
    />
    {/* Global site tag (gtag.js) - Google Analytics */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());

          gtag('config', '${(function () {
            switch (country) {
              case 'cz':
              case 'sk':
                return 'AW-604203976';
              case 'ua':
                return 'AW-10850542638';
              case 'pl':
                return 'AW-11017503479';
              default:
                return '';
            }
          })()}');
          `,
      }}
    />
    {/* Hotjar Tracking Code for www.dramox.cz */}
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `
          (function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 2038079, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
          `,
      }}
    />
    {/* Facebook Pixel Code */}
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `
            var fbId = '${(function () {
              switch (country) {
                case 'cz':
                case 'sk':
                  return '820541082084038';
                case 'ua':
                  return '927360487980003';
                case 'pl':
                  return '467812515221802';
                default:
                  return '567317627801812';
              }
            })()}';
            !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', fbId);
          fbq('track', 'PageView');
          `,
      }}
    />
    {/* Měřicí kód Sklik.cz */}
    <script
      defer
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          var seznam_cId = null;
          var query = (window.location.search || '').replace('?', '').split('=');
          if (query[0] == 'seznam_cId') {
            seznam_cId = query[1];
          }
          var seznam_value = null;
          `,
      }}
    />
    <script
      defer
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          /* <![CDATA[ */
          var seznam_retargeting_id = 108677;
          /* ]]> */
          `,
      }}
    />
    <script type="text/javascript" src="//c.imedia.cz/js/retargeting.js"></script>
    {/* Smartlook */}
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document); smartlook('init', 'b41e656f8f6b07d018b3c20f0323194cc0f54919', { region: 'eu' });
          `,
      }}
    />
  </>
);

export default trackingScripts;
