export function imageUrl(url: string, width?: number, height?: number, quality?: number): string {
  if (!url) {
    return null;
  }

  if (!(width || height) && !quality) {
    return url;
  }

  const domain = url.match(/https:\/\/[a-z0-9.]*/)[0];
  const path = url.replace(domain, '');

  return `${domain}/resize${path}?width=${width || '-'}&height=${height || '-'}&quality=${quality ?? 100}`;
}
