import { createContext, useContext, useState } from 'react';
import { PopupType } from 'enums/popupType';
import { Translate } from 'next-translate';
import React from 'react';

interface PopupConfig {
  popupType: PopupType;
  onClose?: () => void;
  t?: Translate;
  data?: unknown;
}

interface PopupContext extends PopupConfig {
  setPopupConfig: (config: PopupConfig) => void;
  setPopup: (type: PopupType) => void;
}

export const popupContext = createContext<PopupContext>({
  popupType: null,
  onClose: null,
  t: null,
  setPopupConfig: null,
  setPopup: null,
  data: null,
});

export function usePopup(): PopupContext {
  return useContext(popupContext);
}

export function useProvidePopup(): {
  setPopup: (popupType: PopupType) => void;
  setPopupConfig: typeof setPopupConfig;
} & PopupConfig {
  const [popup, setPopupConfig] = useState<PopupConfig>(null);

  const setPopup = (popupType: PopupType) => {
    setPopupConfig({
      popupType,
    });
  };

  return {
    ...popup,
    setPopupConfig,
    setPopup,
  };
}

export function ProvidePopup({ children }: { children: React.ReactChild }): JSX.Element {
  const popup = useProvidePopup();
  return <popupContext.Provider value={popup}>{children}</popupContext.Provider>;
}
