import utf from 'utf8';

export function sortByKeys<T>(array: T[], sortAttr: string, sortKeys: string[]): Array<T> {
  const result = sortKeys?.map((sk) => array?.find((item) => item[sortAttr] === sk));
  const rest = array?.filter((item) => sortKeys.indexOf(item[sortAttr]) === -1);
  return result.concat(rest).filter((r) => !!r);
}

export function utf8_encode(str: string): string {
  try {
    return utf.decode(utf.encode(str));
  } catch {
    return str;
  }
}

export function utf8_decode(str: string): string {
  try {
    return utf.decode(str);
  } catch {
    return str;
  }
}

export function truncateTitle(title: string): string {
  if (title && title.length >= 45) {
    return title.split('').slice(0, title.lastIndexOf(' ', 45)).join('') + '...';
  }
  return title;
}

export function sortAlphabetically<T>(array: T[], attr: string): Array<T> {
  return array?.sort((a, b) => {
    if (attr in a && attr in b) {
      return a[attr].localeCompare(b[attr]);
    }
    return 0;
  });
}

export function unifyArray<T>(array: Array<T>): Array<T> {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}

export const isServer = (): boolean => typeof window === `undefined`;

// export const encodeBase64 = (obj) => {
//   const str = JSON.stringify(obj);
//   return Buffer.from(str).toString('base64');
// };

export const userLogo = (name: string): string => (name && name.length > 0 ? name[0] : '');

export const formatCard = (lastDigits: string): string => `•••• •••• •••• ${lastDigits}`;

/**
 * Truncates the given text to the specified length without breaking the words.
 *
 * @param {string} text
 * @param {number} max
 * @returns {string}
 */
export function truncate(text: string, max: number): string {
  return text && text.length > max ? `${text.slice(0, max).split(' ').slice(0, -1).join(' ')}...` : text;
}

export function truncateSeoDescription(desc: string): string {
  return truncate(desc, 157);
}

enum Plural {
  Zero = 'zero',
  One = 'one',
  Few = 'few',
  Many = 'many',
}
export function determinePluralFromNumber(num: number): Plural {
  if (num === 0) {
    return Plural.Zero;
  } else if (num === 1) {
    return Plural.One;
  } else if (num <= 4) {
    return Plural.Few;
  } else {
    return Plural.Many;
  }
}
