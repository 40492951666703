import { imageUrl } from 'helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';

interface Props {
  src: string;
  width?: number;
  height?: number;
  quality?: number;
  alt?: string;
  className?: string;
  placeholderClass?: string;
  loadImmediately?: boolean;
  usePlaceholder?: boolean;
}

const DramoxImage = ({
  src,
  width = 0,
  height = 0,
  quality,
  alt,
  className,
  placeholderClass,
  loadImmediately = true,
  usePlaceholder = false,
}: Props): JSX.Element => {
  const ref = useRef<HTMLImageElement>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (ref?.current?.clientWidth) {
      // image loaded in dom, hide placeholder
      setLoaded(true);
    }
  }, [ref?.current?.clientWidth]);

  const image = useMemo(() => {
    if (!src) {
      return null;
    }

    return (
      <img
        ref={ref}
        className={[className, loaded ? styles.loaded : ''].join(' ')}
        srcSet={`${imageUrl(src, width, height, quality)} 1x, ${imageUrl(src, width * 2, height * 2, quality)} 2x`}
        src={imageUrl(src, width * 2, height * 2, quality)}
        width={width}
        height={height}
        alt={alt}
      />
    );
  }, [src]);

  if (loadImmediately && !usePlaceholder) {
    return <>{image}</>;
  }

  return <div className={[placeholderClass, styles.imgPlaceholder].join(' ')}>{loadImmediately && <>{image}</>}</div>;
};

export default DramoxImage;
