import BasePopup from './BasePopup';
import AppLink from '../AppLink/AppLink';
import useTranslation from 'next-translate/useTranslation';
import { Namespace } from 'enums/namespaces';
import { useLang } from 'context/langContext';
import { Permalink } from 'helpers';
import Trans from 'next-translate/Trans';
import { usePopup } from 'context';

interface Props {
  onClose?: () => void;
}

const LoginPopup = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation(Namespace.Popups);
  const { locale } = useLang();
  const { setPopup } = usePopup();

  return (
    <BasePopup showCloseIcon={false} classes={[]} onClose={onClose}>
      <h2>{t('popups:login_title')}</h2>
      <p>{t('popups:login_content')}</p>
      <div className="buttons">
        <AppLink href={Permalink.login(locale)} onClick={() => setPopup(null)}>
          <a className="btn btn-primary">{t('popups:login_action')}</a>
        </AppLink>
      </div>
      <p>
        <Trans i18nKey={'popups:login_footer'} components={[<a href={Permalink.registerStep1(locale)}></a>]} />
      </p>
    </BasePopup>
  );
};

export default LoginPopup;
