import {
  PaymentIntentResult,
  PaymentMethodResult,
  SetupIntentResult,
  Stripe,
  StripeCardElement,
  StripeConstructorOptions,
} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { NextPage, NextPageContext } from 'next';
import useTranslation from 'next-translate/useTranslation';
import {
  createContext,
  Dispatch,
  FunctionComponent,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface StripeContext {
  stripe: Stripe;
  createPaymentMethod: () => Promise<PaymentMethodResult>;
  confirmCardPayment: (clientSecret: string, name?: string, paymentMethod?: string) => Promise<PaymentIntentResult>;
  cardHasError: boolean;
  stripeCard: MutableRefObject<HTMLDivElement>;
  stripeError: MutableRefObject<HTMLSpanElement>;
  setElementsLoaded: Dispatch<SetStateAction<boolean>>;
  confirmCardSetup: (clientSecret: string, name: string) => Promise<SetupIntentResult>;
  enableCardInput: () => void;
  disableCardInput: () => void;
}

const stripeContext = createContext<StripeContext>({
  stripe: null,
  createPaymentMethod: null,
  // redirectToCheckout: null,
  confirmCardPayment: null,
  cardHasError: true,
  stripeCard: null,
  stripeError: null,
  setElementsLoaded: null,
  confirmCardSetup: null,
  disableCardInput: null,
  enableCardInput: null,
});

export const useStripeCtx = (): StripeContext => {
  return useContext(stripeContext);
};

export function ProvideStripe<CP, IP = CP>(
  WrappedComponent: NextPage<CP, IP>,
): FunctionComponent<CP> & {
  getInitialProps?(context: NextPageContext): IP | Promise<IP>;
} {
  const WithAuthRedirectWrapper: NextPage<CP, IP> = (props) => {
    const stripeCtx = useStripeProvider();
    return (
      <stripeContext.Provider value={stripeCtx}>
        <WrappedComponent {...props} />
      </stripeContext.Provider>
    );
  };
  return WithAuthRedirectWrapper;
}

function useStripeProvider() {
  const [stripe, setStripe] = useState<Stripe>();
  const { lang } = useTranslation();
  const card = useRef<StripeCardElement>();
  const [cardHasError, setCardHasError] = useState(true);
  const [elementsLoaded, setElementsLoaded] = useState(false);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null);

  const stripeCard = useRef(null);
  const stripeError = useRef(null);

  // init stripe after "render"
  useEffect(() => {
    const initStripe = async () => {
      try {
        const _stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_TOKEN, {
          locale: lang as StripeConstructorOptions['locale'],
        });
        setStripe(_stripe);
      } catch (e) {
        console.warn('Failed to load stripe instance');
      }
    };
    initStripe();
  }, []);

  useEffect(() => {
    const load = async () => {
      // Create an instance of Elements.
      const elements = stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const style = {
        base: {
          iconColor: 'white',
          color: 'white',
          fontSmoothing: 'antialiased',
          fontSize: '18px',
          '::placeholder': {
            color: 'rgba(white, .7)',
          },
        },
        invalid: {
          color: '#ffab2e',
          iconColor: '#ffab2e',
        },
      };

      // Create an instance of the card Element.
      const cardElement = elements.create('card', { style: style });

      // Add an instance of the card Element into the <div>.
      if (stripeCard.current) {
        cardElement.mount(stripeCard.current);

        // add classes when focus/blur
        const clsFocus = 'focus';
        const clsError = 'error';

        cardElement.on('focus', () => {
          stripeCard.current.classList.add(clsFocus);
        });
        cardElement.on('blur', () => {
          stripeCard.current.classList.remove(clsFocus);
        });

        // Handle real-time validation errors from the card Element.
        cardElement.on('change', (event) => {
          // console.log('event.error ', event.error);
          if (event.error) {
            stripeCard.current.classList.add(clsError);
            stripeError.current.textContent = event.error.message;
          } else {
            stripeCard.current.classList.remove(clsError);
          }
          setCardHasError(!!event.error);
        });

        card.current = cardElement;
        setCardElement(cardElement);
      }
    };
    if (stripeCard.current !== null) {
      try {
        if (stripe) {
          load();
        }
      } catch (e) {
        console.log('loading stripe ...', e);
      }
    }

    return () => {
      setElementsLoaded(false);
    };
  }, [elementsLoaded, stripe]);

  const createPaymentMethod = () =>
    stripe.createPaymentMethod({
      type: 'card',
      card: card.current,
    });

  const confirmCardPayment = async (client_secret: string, name?: string, paymentMethod?: string) => {
    return stripe.confirmCardPayment(client_secret, {
      payment_method: card?.current
        ? {
            card: card.current,
            billing_details: {
              name,
            },
          }
        : paymentMethod,
    });
  };

  const confirmCardSetup = async (clientSecret: string, name: string) =>
    stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: card.current,
        billing_details: {
          name,
        },
      },
    });
  // const redirectToCheckout = async (sessionId: string) => stripe.redirectToCheckout({ sessionId });

  const enableCardInput = useCallback(() => {
    cardElement?.update({ disabled: false });
  }, [cardElement]);

  const disableCardInput = useCallback(() => {
    cardElement?.update({ disabled: true });
  }, [cardElement]);

  return {
    stripe,
    setElementsLoaded,
    createPaymentMethod,
    confirmCardPayment,
    // redirectToCheckout,
    cardHasError,
    stripeCard,
    stripeError,
    confirmCardSetup,
    enableCardInput,
    disableCardInput,
  };
}
