import { AppApiError } from 'enums';
import { getLocaleFromUrl, isServer } from 'helpers';
import useTranslation from 'next-translate/useTranslation';
import { parseCookies } from 'nookies';
import { locales } from '../i18n';
import { useAuth } from './authContext';

export const useLang = (): { locale: string } => {
  const { user } = useAuth();
  const { lang } = useTranslation();
  const cookies = parseCookies();

  let locale: string;
  if (!isServer) {
    const [, _locale] = window.location.pathname.split('/');
    if (locales.indexOf(_locale) > -1) {
      locale = _locale;
    }
  }

  locale =
    locale ?? user?.language ?? cookies.NEXT_LOCALE ?? (!isServer ? getLocaleFromUrl(window.location.pathname) : lang);

  return {
    locale,
  };
};

export const useLocalisedError = (): { localisedError: (errorCode: AppApiError) => string } => {
  const { t } = useTranslation();

  function localisedError(errorCode: AppApiError) {
    switch (errorCode) {
      case AppApiError.CodeNoLongerValid:
        return t('choose-plan:error_code_no_longer_valid');
      case AppApiError.CodeDoesNotExist:
        return t('choose-plan:code_not_exists');
      case AppApiError.DiscountAlreadyUsed:
        return t('choose-plan:error_code_discount_already_used');
      case AppApiError.OtherDiscountIsActive:
        return t('choose-plan:error_discount_already_active');
      case AppApiError.PromoReferralNotAllowed:
        return t('choose-plan:error_promo_referral_not_allowed');
      case AppApiError.PromoAlreadyUsed:
        return t('choose-plan:error_promo_already_used');
      case AppApiError.PromoNotAllowed:
        return t('choose-plan:error_promo_not_allowed');
      case AppApiError.VoucherAlreadyUsed:
        return t('choose-plan:error_promo_voucher_already_used');
      case AppApiError.VoucherExpired:
        return t('choose-plan:error_voucher_expired');
      case AppApiError.VoucherDoesNotExist:
        return t('choose-plan:code_not_exists');
      default:
        return null;
    }
  }

  return {
    localisedError,
  };
};
