import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  title: string | JSX.Element;
  content: string | JSX.Element;
  expandedHeaderClassName?: string;
  onToggle?: () => void;
}

export const Accordion: React.FC<Props> = (props) => {
  const { title, content, expandedHeaderClassName, onToggle } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleToggle = () => {
    setExpanded(!expanded);

    if (onToggle) {
      onToggle();
    }
  };

  return (
    <>
      <motion.h5
        initial={false}
        className={classNames(styles.accordionHeader, expanded ? expandedHeaderClassName : null)}
        onClick={handleToggle}
      >
        {title}
      </motion.h5>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.48, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {content}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};
