const RouteType = {
  DefaultRedirect: 'redirect', // dummy

  Collection: {
    cs: 'nabidka',
    en: 'browse',
    sk: 'ponuka',
    uk: 'perehlyadaty',
    pl: 'przegladaj',
  },
  Welcome: '',
  PlayDetail: {
    cs: 'divadla/[slug]/[playSlug]',
    en: 'theatres/[slug]/[playSlug]',
    sk: 'divadla/[slug]/[playSlug]',
    uk: 'teatry/[slug]/[playSlug]',
    pl: 'teatry/[slug]/[playSlug]',
  },
  TheatreDetail: {
    cs: 'divadla/[slug]',
    en: 'theatres/[slug]',
    sk: 'divadla/[slug]',
    uk: 'teatry/[slug]',
    pl: 'teatry/[slug]',
  },
  Theatres: {
    cs: 'divadla',
    en: 'theatres',
    sk: 'divadla',
    uk: 'teatry',
    pl: 'teatry',
  },
  Playlist: 'playlist',
  FeaturedSection: {
    cs: 'sekce/[slug]',
    en: 'section/[slug]',
    sk: 'sekcia/[slug]',
    uk: 'rozdil/[slug]',
    pl: 'sekcja/[slug]',
  },
  SameGenre: {
    cs: 'stejny-zanr/[slug]',
    en: 'same-genre/[slug]',
    sk: 'rovnaky-zaner/[slug]',
    uk: 'schozhe-za-zhanrom/[slug]',
    pl: 'ten-sam-gatunek/[slug]',
  },
  SameCast: {
    cs: 'stejne-obsazeni/[slug]',
    en: 'same-cast/[slug]',
    sk: 'rovnake-obsadenie/[slug]',
    uk: 'ti-sami-aktory/[slug]',
    pl: 'ten-sam-aktor/[slug]',
  },
  Genre: {
    cs: 'zanr/[slug]',
    en: 'genre/[slug]',
    sk: 'zaner/[slug]',
    uk: 'zhanr/[slug]',
    pl: 'gatunek/[slug]',
  },
  MostPopular: {
    en: 'most-popular-theatre-performances',
    cs: 'nejpopularnejsi-divadelni-predstaveni',
    sk: 'najpopularnejsie-divadelne-predstavenia',
    uk: 'najpopulyarnishi-teatralni-vystavy',
    pl: 'najpopularniejsze-spektakle-teatralne',
  },
  RecentlyAdded: {
    en: 'recently-added-theatre-performances',
    cs: 'nove-pridana-divadelni-predstaveni',
    sk: 'novopridane-divadelne-predstavenia',
    uk: 'novi-teatralni-vystavy',
    pl: 'ostatnio-dodane-przedstawienia',
  },
  CurrentlyWatching: {
    en: 'currently-watching',
    cs: 'prave-rozkoukane',
    sk: 'prave-pozerate',
    uk: 'zaraz-dyvytes',
    pl: 'ostatnio-ogladane',
  },
  DramoxOriginals: {
    en: 'dramox-originals',
    cs: 'original-dramox',
    sk: 'dramox-originals',
    uk: 'dramox-originals',
    pl: 'dramox-originals',
  },
  Celebrity: {
    cs: 'osobnost/[slug]',
    en: 'celebrity/[slug]',
    sk: 'osobnost/[slug]',
    uk: 'znamenytist/[slug]',
    pl: 'gwiazdy/[slug]',
  },
  Donate: {
    cs: 'prispevek-divadlu',
    en: 'donate',
    sk: 'prispevok-divadlu',
    uk: 'pidtrymaty',
    pl: 'darowizna',
  },
  DonateTheatre: {
    en: 'donate/[slug]',
    cs: 'prispevek-divadlu/[slug]',
    sk: 'prispevok-divadlu/[slug]',
    uk: 'pidtrymaty-teatr/[slug]',
    pl: 'darowizna/[slug]',
  },
  About: {
    en: 'about-us',
    cs: 'o-nas',
    sk: 'o-nas',
    uk: 'pro-nas',
    pl: 'o-nas',
  },
  Voucher: {
    en: 'gift-vouchers',
    cs: 'darkove-poukazy',
    sk: 'darcekove-poukazy',
    uk: 'podarunkovyi-vaucher',
    pl: 'voucher-podarunkowy',
  },
  VoucherBuy: {
    en: 'buy-gift-voucher',
    cs: 'koupit-darkovy-poukaz',
    sk: 'kupit-darcekovy-poukaz',
    uk: 'kupyty-podarunkovyi-vaucher',
    pl: 'kup-voucher-podarunkowy',
  },
  ForSchools: {
    en: 'for-schools',
    cs: 'pro-skoly',
    sk: 'pre-skoly',
    uk: 'dlya-shkil',
    pl: 'dla-szkol',
  },
  SmartTV: {
    en: 'smart-tv',
    cs: 'chytre-televize',
    sk: 'smart-tv',
    uk: 'smart-tv',
    pl: 'smart-tv',
  },
  Contact: {
    en: 'contact',
    cs: 'kontakt',
    sk: 'kontakt',
    uk: 'kontakty',
    pl: 'kontakt',
  },
  FAQ: {
    en: 'frequently-asked-questions',
    cs: 'casto-kladene-dotazy',
    sk: 'casto-kladene-otazky',
    uk: 'poshyreni-pytannya',
    pl: 'najczesciej-zadawane-pytania',
  },
  Login: {
    en: 'login',
    cs: 'prihlaseni',
    sk: 'prihlasenie',
    uk: 'uviity',
    pl: 'login',
  },
  RegisterStep1: {
    en: 'register/step-1',
    cs: 'registrace/krok-1',
    sk: 'registracia/krok-1',
    uk: 'rejestraciya/krok-1',
    pl: 'rejestracja/krok-1',
  },
  RegisterStep2: {
    en: 'register/step-2',
    cs: 'registrace/krok-2',
    sk: 'registracia/krok-2',
    uk: 'rejestraciya/krok-2',
    pl: 'rejestracja/krok-2',
  },
  RegisterConfirmEmail: {
    en: 'register/confirm-email',
    cs: 'registrace/potvrzeni-emailu',
    sk: 'registracia/potvrdenie-emailu',
    uk: 'rejestraciya/pidtverdzhenya-email',
    pl: 'rejestracja/potwierdz-email',
  },
  RegisterContentSettings: {
    en: 'register/content-settings',
    cs: 'registrace/nastaveni-obsahu',
    sk: 'registracia/nastavenie-obsahu',
    uk: 'rejestraciya/nalashtuvannia-vmistu',
    pl: 'rejestracja/ustawienia-zawartosci',
  },
  ForgottenPassword: {
    en: 'forgotten-password',
    cs: 'zapomenute-heslo',
    sk: 'zabudnute-heslo',
    uk: 'zabuly-parol',
    pl: 'zapomniane-haslo',
  },
  Profile: 'profile',
  TermsAndConditions: {
    en: 'terms-and-conditions',
    cs: 'podminky-pouziti',
    sk: 'podmienky-pouzivania',
    uk: 'polozhennya-ta-umovy',
    pl: 'zasady-uzytkowania',
  },
  PrivacyPolicy: {
    en: 'privacy-policy',
    cs: 'ochrana-soukromi',
    sk: 'ochrana-sukromia',
    uk: 'konfidencijnist',
    pl: 'polityka-prywatnosci',
  },
  Cookies: {
    en: 'cookies',
    cs: 'cookies',
    sk: 'cookies',
    uk: 'cookies',
    pl: 'cookies',
  },
  Authorship: {
    en: 'authorship',
    cs: 'autorska-prava',
    sk: 'autorske-prava',
    uk: 'avtorski-prava',
    pl: 'prawa-autorskie',
  },
  ChoosePlan: 'choose-plan',
  Watch: {
    cs: 'sledovat-divadelni-predstaveni-online/[slug]',
    en: 'watch-online/[slug]',
    sk: 'sledovat-divadlo-online/[slug]',
    uk: 'dyvytysya-onlain/[slug]',
    pl: 'ogladaj-online/[slug]',
  },
  Preview: {
    cs: 'ukazka-predstaveni/[slug]',
    en: 'preview/[slug]',
    sk: 'ukazka-predstavenia/[slug]',
    uk: 'poperednij-perehlyad/[slug]',
    pl: 'zapowiedz/[slug]',
  },
  HowToWatch: {
    cs: 'jak-sledovat',
    en: 'how-to-watch',
    sk: 'ako-sledovat',
    uk: 'jak-dyvytys',
    pl: 'jak-ogladac',
  },
  HowToWatchWeb: {
    cs: 'jak-sledovat/web',
    en: 'how-to-watch/web',
    sk: 'ako-sledovat/web',
    uk: 'jak-dyvytys/web',
    pl: 'jak-ogladac/web',
  },
  HowToWatchMobile: {
    cs: 'jak-sledovat/mobilni-aplikace',
    en: 'how-to-watch/mobile-apps',
    sk: 'ako-sledovat/mobilna-aplikacia',
    uk: 'jak-dyvytys/mobilnyi-dodatok',
    pl: 'jak-ogladac/aplikacja-mobilna',
  },
  HowToWatchMobileAndroid: {
    cs: 'jak-sledovat/mobilni-aplikace/google-android',
    en: 'how-to-watch/mobile-apps/google-android',
    sk: 'ako-sledovat/mobilna-aplikacia/google-android',
    uk: 'jak-dyvytys/mobilnyi-dodatok/google-android',
    pl: 'jak-ogladac/aplikacja-mobilna/google-android',
  },
  HowToWatchMobileIOS: {
    cs: 'jak-sledovat/mobilni-aplikace/apple-ios',
    en: 'how-to-watch/mobile-apps/apple-ios',
    sk: 'ako-sledovat/mobilna-aplikacia/apple-ios',
    uk: 'jak-dyvytys/mobilnyi-dodatok/apple-ios',
    pl: 'jak-ogladac/aplikacja-mobilna/apple-ios',
  },
  HowToWatchSmartTV: {
    cs: 'jak-sledovat/chytre-televize',
    en: 'how-to-watch/smart-tv',
    sk: 'ako-sledovat/smart-tv',
    uk: 'jak-dyvytys/smart-tv',
    pl: 'jak-ogladac/smart-tv',
  },
  HowToWatchSmartTVSamsung: {
    cs: 'jak-sledovat/chytre-televize/samsung-tizen',
    en: 'how-to-watch/smart-tv/samsung-tizen',
    sk: 'ako-sledovat/smart-tv/samsung-tizen',
    uk: 'jak-dyvytys/smart-tv/samsung-tizen',
    pl: 'jak-ogladac/smart-tv/samsung-tizen',
  },
  HowToWatchSmartTVLG: {
    cs: 'jak-sledovat/chytre-televize/lg-webos',
    en: 'how-to-watch/smart-tv/lg-webos',
    sk: 'ako-sledovat/smart-tv/lg-webos',
    uk: 'jak-dyvytys/smart-tv/lg-webos',
    pl: 'jak-ogladac/smart-tv/lg-webos',
  },
  HowToWatchStreaming: {
    cs: 'jak-sledovat/sdileni-obrazovky',
    en: 'how-to-watch/video-streaming',
    sk: 'ako-sledovat/zdielanie-obrazovky',
    uk: 'jak-dyvytys/video-translyaciya',
    pl: 'jak-ogladac/udostepnianie-obrazu',
  },
  HowToWatchStreamingChromecast: {
    cs: 'jak-sledovat/sdileni-obrazovky/google-chromecast',
    en: 'how-to-watch/video-streaming/google-chromecast',
    sk: 'ako-sledovat/zdielanie-obrazovky/google-chromecast',
    uk: 'jak-dyvytys/video-translyaciya/google-chromecast',
    pl: 'jak-ogladac/udostepnianie-obrazu/google-chromecast',
  },
  HowToWatchStreamingAirplay: {
    cs: 'jak-sledovat/sdileni-obrazovky/apple-airplay',
    en: 'how-to-watch/video-streaming/apple-airplay',
    sk: 'ako-sledovat/zdielanie-obrazovky/apple-airplay',
    uk: 'jak-dyvytys/video-translyaciya/apple-airplay',
    pl: 'jak-ogladac/udostepnianie-obrazu/apple-airplay',
  },
  ContentSettings: {
    cs: 'nastaveni-obsahu',
    en: 'content-settings',
    sk: 'nastavenie-obsahu',
    uk: 'nalashuvannya-vmistu',
    pl: 'ustawienia-zawartosci',
  },
  InviteFriends: {
    cs: 'pozvat-pratele',
    en: 'invite-friends',
    sk: 'pozvat-priatelov',
    uk: 'zaprosyty-druziv',
    pl: 'zapros-znajomych',
  },
  WatchList: {
    cs: 'muj-seznam',
    en: 'watch-list',
    sk: 'moj-zoznam',
    uk: 'mij-spysok',
    pl: 'moja-lista',
  },
  UaSupport: {
    cs: 'podpora-ukrajinskym-divadlum',
    en: 'help-ukraine-theatres',
    sk: 'pomoc-ukrajinskym-divadlam',
    uk: 'pidtrymaite-ukrainski-teatry',
    pl: 'pomoz-ukrainskim-teatrom',
  },
  EasterContest: {
    cs: 'velikonocni-soutez',
    en: 'easter-contest',
    sk: 'velkonocna-sutaz',
    uk: 'velykodniy-konkurs',
    pl: 'konkurs-wielkanocny',
  },
  Discount: {
    cs: 'dramox-za-pulku',
    en: 'dramox-50-off',
    sk: 'dramox-za-polovicu',
    uk: 'dramox-50-ua',
    pl: 'dramox-50-pl',
  },
  ChristmasContest: {
    cs: 'vanocni-soutez',
    en: 'christmas-contest',
    sk: 'vianocna-sutaz',
    uk: 'rizdvyanyi-konkurs',
    pl: 'konkurs-swiateczny',
  },
  Discount2022: {
    cs: 'dramox-kveten-33',
    en: 'dramox-may-33',
    sk: 'dramox-maj-33',
    uk: 'dramox-traven-33',
    pl: 'dramox-maj-33',
  },
  Broadcast: {
    cs: 'vysilani',
    en: 'broadcast',
    sk: 'vysilanie',
    uk: 'movlennya',
    pl: 'transmisja',
  },
  ConversionLandingPage: {
    cs: 'homepage',
    en: 'homepage',
    sk: 'homepage',
    uk: 'homepage',
    pl: 'homepage',
  },
};

exports.RouteType = RouteType;
