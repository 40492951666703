import AppLink from 'components/AppLink/AppLink';
import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import storageHelper from 'helpers/storageHelper';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  translationKey: string;
  link?: string;
}

export const InfoToolbar = ({ translationKey, link }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setVisible(!storageHelper.get(translationKey));

    return () => {
      document.body.style.paddingTop = '0';
    };
  }, []);

  useEffect(() => {
    const elHeight = containerRef?.current?.clientHeight;
    document.body.style.paddingTop = visible ? `${elHeight}px` : '0';
  }, [containerRef, visible]);

  const onClose = () => {
    storageHelper.add(translationKey, 'set');
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  const text = t(translationKey);

  return (
    <aside className={styles.container} ref={containerRef}>
      <p>
        {!link && text}
        {!!link && (
          <AppLink href={link}>
            <a>{text}</a>
          </AppLink>
        )}
      </p>
      <span className={styles.close} title="Zavřít" onClick={onClose} />
    </aside>
  );
};
