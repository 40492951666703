import fetch from 'isomorphic-unfetch';
import { baseApiUrl, queryStringFromObject, transformTranslationsIntoNames } from './apiHelpers';
import {
  Celebrity,
  DonationPostRequest,
  Genre,
  GenreBase,
  Play,
  PlayBase,
  PlaysRequest,
  SimilarPlaysRequest,
  Theatre,
  SubscriptionPostResponse,
  User,
  UserRegisterRequest,
  UserRegisterResponse,
  Plan,
  Card,
  PaymentItem,
  UserUpdateRequest,
  UserVoucherResponse,
  SubscriptionPostRequest,
  VoucherPostRequest,
  UserExistsResponse,
  Video,
  SearchResponse,
  Price,
  PopularTheaterAPI,
  TheatresFilters,
} from '../typings/api';
import { AppApiError } from '../enums/errors';
import { CardAddResponse } from '../typings/api/card-add-response';
import { SendEmailRequest } from 'typings/api/send-email-request';
import { Country } from 'typings/api/country';
import { GetCountryInfoResponse, GetHomepageSectionResponse, Homepage } from 'typings/api/homepage';
import { IdNames, GetPromotedContentResponse, IdTranslations, ScheduleItem } from 'typings/notGenerated';
import { Events } from 'enums';
import { parseCookies } from 'nookies';
import { CodeType, PaymentCodes, UserPromo } from '../typings/api/user-promo';
import { PaymentsCodesResponse } from 'typings/api/payments-codes-get-response';
import { CookieAnalytics } from 'enums/cookieAnalytics';
import { domainFromHost } from 'helpers';
import { Socials } from 'typings/api/socials';

export class ApiClient {
  constructor(
    private locale?: string,
    private token?: string,
    private ip?: string,
    private forcedDomain?: string,
    private forceCountryForDev?: string,
  ) {}

  public getDonateOptions(): Promise<Price[]> {
    return this.get<Price[]>('payments/donation/options');
  }

  public getCountryInfo(): Promise<GetCountryInfoResponse> {
    return this.get<GetCountryInfoResponse>('countries/info');
  }

  public getHomepageSection(id: number): Promise<GetHomepageSectionResponse> {
    return this.get<GetHomepageSectionResponse>(`homepage/section/${id}`);
  }

  public getHomepageContent(): Promise<GetPromotedContentResponse> {
    return this.get<GetPromotedContentResponse>(`homepage/promoted`);
  }

  public getPlayDetail(slugOrId: string | number): Promise<Play> {
    let url = `plays/${slugOrId}`;

    if (typeof slugOrId === 'string') {
      url = `plays/slug/${slugOrId}`;
    }

    return this.get<Play>(url);
  }

  public getSimilarPlays(id: number, body?: SimilarPlaysRequest): Promise<PlayBase[]> {
    return this.post<PlayBase[]>(`plays/${id}/similar`, { order: 'desc', order_by: 'date_published_from', ...body });
  }

  public getPlays(body?: PlaysRequest): Promise<PlayBase[]> {
    // order: "desc", order_by: "views",
    return this.post<PlayBase[]>('plays', { ...body });
  }

  public getCurrentlyWatchingPlays(): Promise<PlayBase[]> {
    return this.get<PlayBase[]>('plays/current');
  }

  public getDramoxOriginalsPlays(): Promise<PlayBase[]> {
    return this.get<PlayBase[]>('plays/originals');
  }

  public getTheatreDetail(slugOrId: number | string): Promise<Theatre> {
    if (typeof slugOrId === 'string') {
      return this.getTheatreDetailSlug(slugOrId);
    }

    return this.get<Theatre>(`theatres/${slugOrId}`);
  }

  public getTheatreDetailSlug(slug: string): Promise<Theatre> {
    return this.get<Theatre>(`theatres/slug/${slug}`);
  }

  public getTheatres(query?: {
    plays_count?: boolean;
    include_plays?: number;
    play_genre?: number;
    play_language?: string;
    play_country?: number;
    order_by?: string;
  }): Promise<Theatre[]> {
    return this.get<Theatre[]>('theatres', { ...query });
  }

  public getHomepageSections(user?: User, limit?: number): Promise<Homepage> {
    return this.get<Homepage>('homepage', limit ? { limit } : null);
  }

  public getGenres(): Promise<GenreBase[]> {
    return this.get<GenreBase[]>('genres');
  }

  public getGenre(id: number | string): Promise<Genre> {
    return this.get<Genre>(`genres/${id}`);
  }

  public async getTheatresWithNames(): Promise<IdNames[]> {
    const res = await this.get<{ items: IdTranslations[] }>('translations/theatres/names', null, {
      Authorization: `ApiKey ${process.env.NEXT_PUBLIC_API_KEY}`,
    });
    return transformTranslationsIntoNames(res.items);
  }

  public async getCelebritiesWithNames(): Promise<IdNames[]> {
    const res = await this.get<{ items: IdTranslations[] }>('translations/celebrities/names', null, {
      Authorization: `ApiKey ${process.env.NEXT_PUBLIC_API_KEY}`,
    });
    return transformTranslationsIntoNames(res.items);
  }

  public async getPlaysWithNames(): Promise<IdNames[]> {
    const res = await this.get<{ items: IdTranslations[] }>('translations/plays/names', null, {
      Authorization: `ApiKey ${process.env.NEXT_PUBLIC_API_KEY}`,
    });
    return transformTranslationsIntoNames(res.items);
  }

  public getCelebrityDetail(slugOrId: number | string): Promise<Celebrity> {
    let url = `celebrity/${slugOrId}`;

    if (typeof slugOrId === 'string') {
      url = `celebrity/slug/${slugOrId}`;
    }

    return this.get<Celebrity>(url);
  }

  public getVideo(id: number | string): Promise<Video> {
    return this.get<Video>(`video/${id}`);
  }

  public updateVideoProgress(id: number, progress: number, timeWatched: number, sessionId: number): Promise<Video> {
    return this.post<Video>(`video/${id}/tick`, {
      user_progress: progress,
      time_watched: timeWatched,
      session_id: sessionId,
    });
  }

  public search(query: string): Promise<SearchResponse> {
    return this.post<SearchResponse>('search', { query });
  }

  public paymentDonation(body: DonationPostRequest): Promise<SubscriptionPostResponse> {
    try {
      return this.post<SubscriptionPostResponse>('payments/donation', { ...body });
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.DonationError;
      }
    }
  }

  public getUser(): Promise<User> {
    try {
      return this.get<User>('user');
    } catch (e) {
      throw AppApiError.UserError;
    }
  }

  public checkUserExists(email: string): Promise<UserExistsResponse> {
    return this.post<{ exists: boolean }>('user/exists', { email });
  }

  public createUser(body: UserRegisterRequest): Promise<UserRegisterResponse> {
    return this.post<UserRegisterResponse>('user/register', { ...body });
  }

  public getPlans(): Promise<Plan[]> {
    return this.get<Plan[]>('plans');
  }

  public getUserCards(): Promise<Card[]> {
    return this.get<Card[]>('user/cards');
  }

  public getCountries(): Promise<Country[]> {
    return this.get<Country[]>('countries');
  }

  public getPurchaseHistory(): Promise<PaymentItem[]> {
    return this.get<PaymentItem[]>('user/history');
  }

  public deleteUser(): Promise<void> {
    return this.delete('user');
  }

  public changeDefaultCard(cardId: string): Promise<Card[]> {
    return this.post<Card[]>('user/cards', { id: cardId });
  }

  public removeUserCard(cardId: string): Promise<void> {
    return this.delete(`user/cards/${cardId}`);
  }

  public cancelSubscription(): Promise<void> {
    return this.delete('subscription/change');
  }

  public changeSubscription(planId: number | null): Promise<void> {
    return this.post('subscription/change', { plan_id: planId });
  }

  public resetPassword(email: string): Promise<void> {
    return this.post('user/resetPassword', { email });
  }

  public sendEmail(sendEmail: SendEmailRequest): Promise<void> {
    return this.post('email', { ...sendEmail });
  }

  public async updateUserData(body: UserUpdateRequest): Promise<User> {
    try {
      const res = await this.patch<User>('user', { ...body });
      return res;
    } catch (e) {
      throw AppApiError.PatchUserError;
    }
  }

  public async redeeemVoucher(code: string): Promise<UserVoucherResponse> {
    try {
      return await this.post<UserVoucherResponse>('payments/codes', { code, type: 'VOUCHER' });
    } catch (e) {
      throw e.cause;
    }
  }

  public async redeemCode(code: string, type: CodeType): Promise<UserVoucherResponse> {
    try {
      return await this.post<UserVoucherResponse>('payments/codes', { code, type });
    } catch (e) {
      throw e.cause;
    }
  }

  public async previewPromoCode(code: string): Promise<PaymentsCodesResponse> {
    try {
      return await this.get<PaymentsCodesResponse>('payments/codes', { code });
    } catch (e) {
      throw e.cause;
    }
  }

  public buySubscription(body: SubscriptionPostRequest): Promise<SubscriptionPostResponse> {
    try {
      return this.post<SubscriptionPostResponse>('payments/subscription', { ...body });
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.SubscriptionPaymentError;
      }
    }
  }

  public async paymentVoucher(body: VoucherPostRequest): Promise<SubscriptionPostResponse> {
    try {
      const res = await this.post<SubscriptionPostResponse>('payments/voucher', { ...body });
      return res;
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.VoucherPaymentError;
      }
    }
  }

  public async addCard(): Promise<CardAddResponse> {
    try {
      const res = await this.post<CardAddResponse>('user/cards/add');
      return res;
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.AddCard;
      }
    }
  }

  public async changePassword(): Promise<void> {
    try {
      const res = await this.post<void>('user/changePassword');
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async getPromoCode(): Promise<UserPromo> {
    try {
      const res = await this.get<UserPromo>('user/promo');
      return res;
    } catch (e) {
      if (e.cause === AppApiError.PromoNotAllowed) {
        throw AppApiError.PromoNotAllowed;
      }
    }
  }

  public async addToWatchlist(playId: number): Promise<Play[]> {
    try {
      const res = await this.post<Play[]>('user/watchlist', {}, { play: playId });
      return res;
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.QueryParamsMissing;
      } else if (e.status === 401) {
        throw AppApiError.AuthInvalid;
      }
      throw e;
    }
  }

  public async getWatchList(): Promise<Play[]> {
    try {
      const res = await this.get<Play[]>('user/watchlist');
      return res;
    } catch (e) {
      if (e.status === 401) {
        throw AppApiError.AuthInvalid;
      }
      throw e;
    }
  }

  public async getPaymentCodes(code: string): Promise<PaymentCodes> {
    try {
      const res = await this.get<PaymentCodes>('payments/codes', { code });
      return res;
    } catch (e) {
      throw e.cause;
    }
  }

  public async removeFromWatchlist(playId: number): Promise<void> {
    try {
      const res = await this.delete('user/watchlist', { play: playId });
      return res;
    } catch (e) {
      if (e.status === 400) {
        throw AppApiError.QueryParamsMissing;
      } else if (e.status === 401) {
        throw AppApiError.AuthInvalid;
      }
      throw e;
    }
  }

  public async popularTheatres(): Promise<PopularTheaterAPI> {
    try {
      const res = await this.get<PopularTheaterAPI>('theatres/popular');
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async theatresFilters(): Promise<TheatresFilters> {
    try {
      const res = await this.get<TheatresFilters>('theatres/filters');
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async cookieAnalytics(payload: { salt: string; signature: string; option: CookieAnalytics }): Promise<void> {
    try {
      const res = await this.post<void>('analytics/cookies', payload);
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async getBroadcastSchedule(): Promise<ScheduleItem[]> {
    try {
      return await this.get<ScheduleItem[]>('/videos/live/schedule');
    } catch (e) {
      throw e;
    }
  }

  public getSocials(): Promise<Socials[]> {
    return this.get<Socials[]>('socials');
  }

  private _getHeaders(): { [key: string]: string } {
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': this.locale,
    };
    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    if (this.ip) {
      headers['X-Forwarded-For'] = this.ip;
    }
    if (this.forcedDomain) {
      headers['Domain'] = this.forcedDomain;
    }
    if (this.forceCountryForDev) {
      headers['Debug-Country-Override'] = this.forceCountryForDev;
    }
    return headers;
  }

  private get<T>(
    url: string,
    queryParams: { [index: string]: string | number | boolean } = null,
    headers: { [key: string]: string } = {},
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      return fetch(`${baseApiUrl()}${url}${queryStringFromObject(queryParams)}`, {
        method: 'GET',
        headers: Object.assign({}, this._getHeaders(), headers),
      })
        .then((res) => {
          if (res.status === 204) {
            resolve({} as T);
          } else if (res.status > 204) {
            res.json().then(reject).catch(reject);
          } else {
            res.json().then(resolve).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  private post<T>(
    url: string,
    body: { [index: string]: string | number | boolean } = {},
    queryParams: { [index: string]: string | number | boolean } = null,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${baseApiUrl()}${url}${queryStringFromObject(queryParams)}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: this._getHeaders(),
      })
        .then((res) => {
          if (res.status === 204) {
            resolve({} as T);
          } else if (res.status > 204) {
            res.json().then(reject).catch(reject);
          } else {
            res.json().then(resolve).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  private delete(url: string, queryParams: { [index: string]: string | number | boolean } = null): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(`${baseApiUrl()}${url}${queryStringFromObject(queryParams)}`, {
        method: 'DELETE',
        headers: this._getHeaders(),
      })
        .then(() => resolve(Promise.resolve()))
        .catch(reject);
    });
  }

  private patch<T>(url: string, body: { [index: string]: string | number | boolean }): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${baseApiUrl()}${url}`, {
        method: 'PATCH',
        headers: this._getHeaders(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.status > 204) {
            return reject(reject);
          }
          return resolve(res.json() as unknown as T);
        })
        .catch(reject);
    });
  }
}

let _sharedApiClient: ApiClient;
let _token: string;
let _locale: string;

export function getSharedApiClient(): ApiClient {
  if (!_sharedApiClient) {
    const cookies = parseCookies();
    rebuildApiClient(_locale ?? cookies.NEXT_LOCALE, _token);
  }

  return _sharedApiClient;
}

if (process.browser) {
  window.addEventListener(Events.AuthToken, (e: CustomEvent) => {
    const token = e.detail as string;
    _token = token;
    const cookies = parseCookies();
    rebuildApiClient(_locale ?? cookies.NEXT_LOCALE, _token);
  });

  window.addEventListener(Events.Language, (e: CustomEvent) => {
    const locale = e.detail as string;
    _locale = locale;
    rebuildApiClient(locale, _token);
  });
}

function rebuildApiClient(locale: string, token?: string) {
  let domain: string = null;
  if (!token) {
    domain = domainFromHost(window.location.host);
  }
  _sharedApiClient = new ApiClient(locale, token, null, domain);
}
