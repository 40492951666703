import { Translate } from 'next-translate';
import React, { cloneElement, ReactElement, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import BasePopup from '../BasePopup';
import styles from './index.module.scss';

interface Props {
  t: Translate;
  onClose: () => void;
  iframe: ReactElement<HTMLIFrameElement>;
}

const NewsletterSubscriptionDialog = ({ iframe, onClose }: Props): JSX.Element => {
  const refContent = useRef<HTMLDivElement>(null);
  const [iframeSent, setIframeSent] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const refIframe = useRef<HTMLIFrameElement>(null);

  return (
    <BasePopup
      showCloseIcon={true}
      classes={[styles.newsletterDialog, loaded ? styles.loaded : null]}
      onClose={onClose}
    >
      <div ref={refContent} className={styles.content}>
        {!loaded && (
          <>
            <Skeleton
              duration={2}
              style={{ height: 50, width: '75%', borderRadius: '3px', marginBottom: 12, marginTop: 50 }}
            />
            <Skeleton duration={2} style={{ height: 50, width: '75%', borderRadius: '3px', marginBottom: 12 }} />
            <Skeleton duration={2} style={{ height: 50, width: '75%', borderRadius: '3px', marginBottom: 12 }} />
          </>
        )}

        {cloneElement(iframe, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onLoad: (e) => {
            const iframe = e.currentTarget;
            if (!iframeSent) {
              const form = iframe.contentDocument.querySelector('form');
              form.submit();
              setIframeSent(true);
            } else {
              setLoaded(true);
            }
          },
          ref: refIframe,
        })}
      </div>
    </BasePopup>
  );
};

export default NewsletterSubscriptionDialog;
