const LANGUAGES = {
  en: {
    label: 'English',
  },
  cs: {
    label: 'Čeština',
  },
  sk: {
    label: 'Slovenčina',
  },
  uk: {
    label: 'Українська',
  },
  pl: {
    label: 'Polski',
  },
};

const LANGUAGES_OPTIONS = [
  {
    flag: '🇨🇿',
    label: `🇨🇿&nbsp;&nbsp;${LANGUAGES['cs'].label}`,
    value: 'cs',
  },
  {
    flag: '🇬🇧',
    label: `🇬🇧&nbsp;&nbsp;${LANGUAGES['en'].label}`,
    value: 'en',
  },
  {
    flag: '🇸🇰',
    label: `🇸🇰&nbsp;&nbsp;${LANGUAGES['sk'].label}`,
    value: 'sk',
  },
  {
    flag: '🇺🇦',
    label: `🇺🇦&nbsp;&nbsp;${LANGUAGES['uk'].label}`,
    value: 'uk',
  },
  {
    flag: '🇵🇱',
    label: `🇵🇱&nbsp;&nbsp;${LANGUAGES['pl'].label}`,
    value: 'pl',
  },
];

const locales = ['cs', 'en', 'sk', 'uk', 'pl'];

exports.LANGUAGES = LANGUAGES;
exports.LANGUAGES_OPTIONS = LANGUAGES_OPTIONS;
exports.locales = locales;
