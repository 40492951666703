import useTranslation from 'next-translate/useTranslation';
import { Namespace } from 'enums/namespaces';
import styles from './index.module.scss';
import Trans from 'next-translate/Trans';
import { useRouter } from 'next/router';
import { countryHasNoTrial, getNoTrialTranslationKeyIfNeeded, Permalink } from 'helpers';
import { LINK_APP_STORE, LINK_GOOGLE_PLAY } from 'constants/links';
import { useAuth } from '../../context';
import dynamic from 'next/dynamic';
import { useCallback } from 'react';
const Accordion = dynamic(() => import('../Accordion').then((component) => component.Accordion), { ssr: false });

interface Props {
  type?: 'short' | 'long';
  priceMonthly?: string;
  priceYearly?: string;
  priceYearlyDiscount?: string;
  countryCode: string;
}

export const FAQList: React.FC<Props> = ({
  type = 'long',
  priceMonthly,
  priceYearly,
  priceYearlyDiscount,
  countryCode,
}) => {
  const ns = Namespace[type === 'long' ? 'FAQ' : 'FAQHome'];
  const { t } = useTranslation(ns);
  const { locale } = useRouter();
  const { user } = useAuth();

  const faqs: { q: string; a: string; no_trial_q?: string; no_trial_a?: string }[] = Object.values(
    t(`items`, null, { returnObjects: true }),
  );

  const assembleTranslationKey = useCallback(
    function (index: number) {
      const completeKey = `${ns}:items.${index}.a`;
      if (index === 14 || index === 19) {
        return getNoTrialTranslationKeyIfNeeded(completeKey, user?.home_country ?? countryCode);
      }
      return completeKey;
    },
    [user, countryCode, ns],
  );

  const getQuestion = useCallback(
    function (index: number) {
      if (index === 13 && countryHasNoTrial(user?.home_country ?? countryCode)) {
        return faqs[index].no_trial_q;
      }
      return faqs[index].q;
    },
    [faqs, countryCode, user],
  );

  return (
    <ul className={styles.faqList}>
      {faqs.map((_, i) => {
        return (
          <li key={i}>
            <Accordion
              title={getQuestion(i)}
              content={
                <div>
                  <Trans
                    // `${ns}:items.${i + 1}.a_${country}`
                    i18nKey={assembleTranslationKey(i + 1)}
                    // fallback={`${ns}:items.${i + 1}.a`}
                    values={{ monthly: priceMonthly, yearly: priceYearly, yearlyDiscount: priceYearlyDiscount }}
                    components={{
                      linkMobileAppsGuide: <a href={Permalink.howToWatchMobile(locale)} />,
                      linkSmartTvGuide: <a href={Permalink.smartTV(locale)} />,
                      linkAboutUs: <a href={Permalink.about(locale)} />,
                      linkDonate: <a href={Permalink.donate(locale)} />,
                      linkVouchers: <a href={Permalink.voucher(locale)} />,
                      linkAppStore: <a href={LINK_APP_STORE} target="_blank" />,
                      linkGooglePlay: <a href={LINK_GOOGLE_PLAY} target="_blank" />,
                      linkRegister: <a href={Permalink.registerStep1(locale)} />,
                      p: <p />,
                      linkDramox: <a href={Permalink.root(user, locale)} />,
                    }}
                  />
                </div>
              }
              expandedHeaderClassName={styles.expanded}
            />
          </li>
        );
      })}
    </ul>
  );
};
