import routeTable from 'config/routeTable';

const FORBIDDEN_WHEN_LOGGED = ['register', 'login', 'forgotten-password'];
const FORBIDDEN_WHEN_UNLOGGED = ['profile', 'choose-plan'];

export const isNotAllowWhenLogged = (url: string): boolean => {
  return FORBIDDEN_WHEN_LOGGED.some((forbidden) => url.includes(forbidden));
};

export const isNotAllowWhenUnlogged = (url: string): boolean =>
  FORBIDDEN_WHEN_UNLOGGED.some((forbidden) => url.includes(forbidden));

export function getPrefferedDomainForCountry(country: string): string {
  return routeTable[country] ?? routeTable.default;
}
