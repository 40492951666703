const { locales } = require('./constants/lang');

module.exports = {
  locales,
  defaultLocale: 'en',
  pages: {
    '*': [
      'common',
      'settings',
      'popups',
      'welcome',
      'seo',
      'register',
      'profile',
      'ua-support',
      'discount',
      'christmas-contest',
    ],
    '/': ['faq-home'],
    '/homepage': ['faq-home', 'welcome'],
    '/preview/[slug]': ['player'],
    '/performance/[slug]': ['play-detail'],
    '/watch-online/[slug]': ['play-detail', 'player'],
    'rgx:/theatres*': ['theaters', 'play-detail'],
    'rgx:/donate*': ['theaters', 'choose-plan'],
    '/login': ['login'],
    'rgx:/register/*': ['register', 'settings'],
    '/profile': ['choose-plan', 'profile'],
    'rgx:/newsletter/*': ['profile'],
    '/forgotten-password': ['forgotten-password'],
    '/choose-plan': ['choose-plan', 'profile'],
    'rgx:/gift-vouchers*': ['vouchers'],
    '/frequently-asked-questions': ['faq'],
    '/about-us': ['about-us', 'contact'],
    '/contact': ['contact'],
    'rgx:/smart-tv/*': ['smart-tv'],
    '/for-schools': ['schools'],
    '/authorship': ['authorship'],
    '/email/handle': ['email-handle'],
    '/content-settings': ['profile'],
    'rgx:/how-to-watch/*': ['how-to-watch'],
    'rgx:/how-to-watch/mobile-apps/*': ['mobile-apps'],
    'rgx:/how-to-watch/video-streaming/*': ['video-streaming'],
    '/invite-friends': ['invite-friends'],
    '/ua-support': ['ua-support'],
    '/discount-may-2022': ['discount-may-2022'],
    '/broadcast': ['broadcast'],
    '/christmas-contest': ['christmas-contest'],
  },
  loadLocaleFrom: (lang, ns) => {
    // eslint-disable-next-line no-undef
    return require(`./localization/${lang}/${ns}.json`);
  },
};
