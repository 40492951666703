import * as React from 'react';
import { CSSProperties } from 'react';

export interface SelectOption {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  options: SelectOption[];
  defaultLabel?: string;
  onChange?: (value: string) => void;
  defaultValue?: string | number;
  className?: string;
  value?: string | number;
  style?: CSSProperties;
}

const Select: React.FC<Props> = ({ options, defaultLabel, name, onChange, defaultValue, className, value, style }) => {
  const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <>
      <select
        value={value}
        name={name}
        id={name}
        defaultValue={defaultValue}
        onChange={_onChange}
        className={className}
        style={style}
      >
        {defaultLabel && (
          <option key={-1} value="" disabled>
            {defaultLabel}
          </option>
        )}
        {options?.map((opt, i) => (
          <option key={i} value={opt.value} dangerouslySetInnerHTML={{ __html: opt.label }} />
        ))}
      </select>
      <span></span>
    </>
  );
};

export default Select;
