import { usePopup } from 'context/popupContext';
import { useCallback, useEffect, useRef } from 'react';
import { closeDialog, toggleDialogWithCls } from '../../helpers';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  classes: string[];
  showCloseIcon: boolean;
  closeOnEscape?: boolean;
  allowBackgroundScrolling?: boolean;
  allowCloseOnOverlayClick?: boolean;
  showOverlay?: boolean;
}

const BasePopup = ({
  children,
  onClose,
  classes,
  showCloseIcon,
  closeOnEscape = true,
  allowBackgroundScrolling = false,
  allowCloseOnOverlayClick = true,
  showOverlay = true,
}: Props): JSX.Element => {
  const { setPopup } = usePopup();
  const openDialogCls = 'dialog-open';
  const allowScrollingCls = 'allow-scrolling';

  const elClose = useRef<HTMLAnchorElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refOverlay = useRef<HTMLDivElement>(null);

  const close = useCallback(() => {
    closeDialog(allowScrollingCls);
    setPopup(null);
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (showCloseIcon) {
      elClose.current.addEventListener('click', (e: Event) => {
        e.preventDefault();
        close();
      });
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 992) {
        closeOnEscape && close();
      }
    });
    const cls = [openDialogCls];
    if (allowBackgroundScrolling) {
      cls.push(allowScrollingCls);
    }
    toggleDialogWithCls(cls, closeOnEscape, close);

    return () => {
      closeDialog(allowScrollingCls);
    };
  }, [elClose, closeOnEscape]);

  const onOverlayClick = useCallback(() => {
    if (allowCloseOnOverlayClick) {
      close();
    }
  }, [allowCloseOnOverlayClick]);

  const content = (
    <>
      <div ref={ref} className={`dialog ${classes.join(' ')}`}>
        {showCloseIcon && (
          <a href="#" className="close" ref={elClose}>
            close
          </a>
        )}
        {children}
      </div>
      {showOverlay && <div className="overlay" ref={refOverlay} onClick={onOverlayClick} />}
    </>
  );

  return content;
};

export default BasePopup;
