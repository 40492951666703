export enum Namespace {
  Common = 'common',
  PlayDetail = 'play-detail',
  Theaters = 'theaters',
  Celebrities = 'celebrities',
  Login = 'login',
  Register = 'register',
  Profile = 'profile',
  ForgottenPassword = 'forgotten-password',
  ChoosePlan = 'choose-plan',
  Vouchers = 'vouchers',
  Popups = 'popups',
  Welcome = 'welcome',
  FAQ = 'faq',
  FAQHome = 'faq-home',
  AboutUs = 'about-us',
  Contact = 'contact',
  SmartTV = 'smart-tv',
  Schools = 'schools',
  Authorship = 'authorship',
  Seo = 'seo',
  EmailHandle = 'email-handle',
  HowToWatch = 'how-to-watch',
  MobileApps = 'mobile-apps',
  VideoStreaming = 'video-streaming',
  Settings = 'content_settings_title',
  InviteFriends = 'invite-friends',
  Player = 'player',
  UaSupport = 'ua-support',
  EasterContest = 'easter-contest',
  Discount = 'discount',
  DiscountMay2022 = 'discount-may-2022',
  Broadcast = 'broadcast',
  ChristmasContest = 'christmas-contest',
}
