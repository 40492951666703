import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { defaultLocale } from '../../i18n';
import Layout from '../Layout';
import styles from './BrowseSkeleton.module.scss';

interface Props {
  countryCode?: string;
}

export const BrowseSkeleton: React.FC<Props> = ({ countryCode = defaultLocale }) => {
  const { t } = useTranslation();

  const renderSkeletonSection = (count = 5) => {
    return (
      <div className="scroll-wrapper">
        <ul className="play-list">
          {Array.from(Array(count).keys()).map((_, i) => {
            return (
              <li key={i}>
                <Skeleton duration={2} style={{ height: 144, width: '100%', borderRadius: '3px', marginBottom: 12 }} />
                <h3>
                  <Skeleton duration={2} style={{ height: 22, width: 120 }} />
                </h3>
                <p>
                  <Skeleton duration={2} style={{ height: 19, width: 95 }} />
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <Layout countryCode={countryCode} allowPopups={false}>
      <main>
        <SkeletonTheme baseColor="#22222E" highlightColor="#2D2D3A">
          <div className="carousel-wrapper">
            <ul className="dramox-carousel swiper-wrapper">
              <li className="swiper-slide" style={{ marginBottom: 45 }}>
                <div className="content">
                  <div className="links-wrapper">
                    <Skeleton duration={2} style={{ width: 240 }} />
                  </div>
                  <h3>
                    <Skeleton duration={2} style={{ width: 340, marginBottom: 20, marginLeft: 8 }} />
                  </h3>
                  <p>
                    <Skeleton duration={2} style={{ width: 540, height: 80, marginBottom: 0 }} />
                  </p>
                  <div className={classNames('buttons', styles.buttons)}>
                    <Skeleton duration={2} style={{ height: 50 }} />
                    <Skeleton duration={2} style={{ height: 50 }} />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="category-section">
            <h2>{t('common:recently_added')}</h2>

            {renderSkeletonSection()}
          </div>

          <div className="category-section">
            <h2>{t('common:most_popular')}</h2>

            {renderSkeletonSection(4)}
          </div>

          <div className="category-section">
            <h2>{t('common:comedy')}</h2>

            {renderSkeletonSection(2)}
          </div>
        </SkeletonTheme>
      </main>
    </Layout>
  );
};
