import { isServer } from './common';

export interface IStorageItem {
  key: string;
  value: string;
}

export class StorageItem {
  key: string;
  value: string;

  constructor(data: IStorageItem) {
    this.key = data.key;
    this.value = data.value;
  }
}

// class for working with local storage in browser (common that can use other classes for store some data)
class StorageHelper {
  localStorageSupported: boolean;

  constructor() {
    this.localStorageSupported = !isServer()
      ? typeof window['localStorage'] != 'undefined' && window['localStorage'] != null
      : false;
  }

  // add value to storage
  add(key: string, item: string) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, item);
    }
  }

  // add value to storage
  saveJSON(key: string, item: { [index: string]: string | number }) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, JSON.stringify(item));
    }
  }

  // get all values from storage (all items)
  getAllItems(): Array<StorageItem> {
    const list = new Array<StorageItem>();

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);

      list.push(
        new StorageItem({
          key: key,
          value: value,
        }),
      );
    }

    return list;
  }

  // get only all values from localStorage
  getAllValues<T>(): Array<T> {
    const list = new Array<T>();

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = (localStorage.getItem(key) as unknown) as T;

      list.push(value);
    }

    return list;
  }

  // get one item by key from storage
  get<T>(key: string): T {
    if (this.localStorageSupported) {
      const item = localStorage.getItem(key);
      return (item as unknown) as T;
    } else {
      return null;
    }
  }

  getJSON<T>(key: string): T {
    if (this.localStorageSupported) {
      const item: string = this.get(key);
      if (item) {
        return JSON.parse(item) as T;
      }

      return null;
    } else {
      return null;
    }
  }

  updateJSON<T>(key: string, update: { [index: string]: string | number }): T {
    if (this.localStorageSupported) {
      const current = this.getJSON<T>(key) ?? {};

      const updated = Object.assign(current, update);
      this.saveJSON(key, updated);

      return null;
    } else {
      return null;
    }
  }

  // remove value from storage
  remove(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    }
  }

  // clear storage (remove all items from it)
  clear() {
    if (this.localStorageSupported) {
      localStorage.clear();
    }
  }
}

export default new StorageHelper();
