import { IdNames, IdTranslations } from 'typings/notGenerated';

export function queryStringFromObject(params: { [index: string]: string | number | boolean }): string {
  const paramString = Object.keys(params ?? [])
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return paramString.length > 0 ? `?${paramString}` : '';
}

export function parseQueryString(str: string): { [index: string]: string } {
  if (!str) {
    return {};
  }

  return JSON.parse('{"' + decodeURI(str).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export function baseApiUrl(): string {
  // for the browser always use the public API url
  if (process.browser) {
    return process.env.NEXT_PUBLIC_API_URL;
  }

  // for production try to use API within internal network, fallback to public API url/ todo: @petr
  return process.env.API_INTERNAL_URL || process.env.NEXT_PUBLIC_API_URL;
}

export const sleep = (m: number): Promise<unknown> => new Promise((r) => setTimeout(r, m));

interface ServerErrorOut {
  error: string;
  cause: string;
  hints?: string[];
}

export function isServerError(response: JSON | ServerErrorOut): response is ServerErrorOut {
  return (response as ServerErrorOut)?.error !== undefined;
}

export function transformTranslationsIntoNames(translations: IdTranslations[]): IdNames[] {
  return translations.map((t) => ({ id: t.id, names: t.translations }));
}
