import Header from '../Header/Header';
import styles from './Layout.module.scss';
import { useCloseMobileMenu } from '../../hooks';
import Footer from '../Footer/Footer';
import Meta from '../Meta';
import { popupContext, usePopup } from '../../context/popupContext';
import { PopupType } from 'enums/popupType';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { NotificationList } from 'containers';
import NewsletterSubscriptionDialog from 'components/Popups/NewsletterSubscriptionDialog';
import LoginPopup from 'components/Popups/Login';
import LowQualityPopup from '../Popups/LowQualityPopup';
import { CodeType, DurationUnit } from 'typings/api/user-promo';
import { parseCookies } from 'nookies';
import BareHeader from 'components/Header/BareHeader';
import { Plan } from 'typings/api';

const BuySubscriptionPopup = dynamic(() => import('components/Popups/BuySubscriptionPopup'), { ssr: false });
const ChangePrimaryCard = dynamic(() => import('components/Popups/ChangePrimaryCard'), { ssr: false });
const DeleteDialog = dynamic(() => import('components/Popups/DeleteUser'), { ssr: false });
const RemoveCard = dynamic(() => import('components/Popups/RemoveCard'), { ssr: false });
const RemoveSubsciptionDialog = dynamic(() => import('components/Popups/RemoveSubsciption'), { ssr: false });
const PromoDiscountDialog = dynamic(() => import('components/Popups/PromoDiscount'), { ssr: false });
const CookiesPopup = dynamic(() => import('components/Popups/CookiesPopup'), { ssr: false });
const CookiesBar = dynamic(() => import('components/Popups/CookiesBar'), { ssr: false });

interface Props {
  children?: React.ReactNode;
  hiddenWrappers?: boolean;
  countryCode: string;
  showBareHeader?: boolean;
  allowPopups?: boolean;
}

const Layout = ({
  children,
  hiddenWrappers = false,
  showBareHeader,
  countryCode,
  allowPopups = true,
}: Props): JSX.Element => {
  const { t: _t } = useTranslation();
  const { setPopup } = usePopup();
  const [allowAnalytics, setAllowAnalytics] = useState(false);
  const [showCookieBar, setShowCookieBar] = useState(false);
  // ui hooks
  useCloseMobileMenu();

  const updateTrackingAllowanceBasedOnCookies = useCallback(() => {
    const cookies = parseCookies();
    const shouldShowCookieBar = cookies.ALLOW_GDPR === undefined;
    setAllowAnalytics(cookies.ALLOW_GDPR === 'true' || shouldShowCookieBar);
    setShowCookieBar(shouldShowCookieBar);
  }, []);

  useEffect(updateTrackingAllowanceBasedOnCookies, []);

  useEffect(() => {
    if (showCookieBar) {
      // delay cookies bar for a bit so the animation doesn't repeat on redirects
      setTimeout(() => {
        setPopup(PopupType.CookiesBar);
      }, 1000);
    }
  }, [showCookieBar]);

  useEffect(() => {
    if (!allowPopups) {
      setPopup(null);
    }
  }, [allowPopups]);

  return (
    <popupContext.Consumer>
      {({ popupType, onClose, data, t }) => (
        <>
          {children && (
            <>
              <Meta analyticsAllowed={allowAnalytics} countryCode={countryCode} />
              <div className={styles.containerFluid}>
                {!hiddenWrappers &&
                  (showBareHeader ? <BareHeader countryCode={countryCode} /> : <Header countryCode={countryCode} />)}
                <NotificationList />
                {children}
                {!hiddenWrappers && <Footer countryCode={countryCode}></Footer>}
              </div>
            </>
          )}
          {allowPopups && popupType === PopupType.Login && <LoginPopup onClose={onClose} />}
          {allowPopups && popupType === PopupType.BuySubscription && <BuySubscriptionPopup onClose={onClose} />}
          {allowPopups && popupType === PopupType.LowQuality && <LowQualityPopup onClose={onClose} />}
          {allowPopups && popupType === PopupType.DeleteUser && <DeleteDialog onClose={onClose} t={t ?? _t} />}
          {allowPopups && popupType === PopupType.RemoveSubscription && (
            <RemoveSubsciptionDialog
              hasPromo={(data as { hasPromo: boolean }).hasPromo}
              isGracePeriodPopup={(data as { isGracePeriod: boolean }).isGracePeriod}
              plan={(data as { plan: Plan }).plan}
              onClose={onClose}
              t={t ?? _t}
            />
          )}
          {allowPopups && popupType === PopupType.DeleteCard && (
            <RemoveCard t={t} cardId={data as string} onClose={onClose} />
          )}
          {allowPopups && popupType === PopupType.NewsletterSubscription && (
            <NewsletterSubscriptionDialog
              onClose={onClose}
              iframe={data as ReactElement<HTMLIFrameElement>}
              t={t ?? _t}
            />
          )}
          {allowPopups && popupType === PopupType.SetPrimaryCard && (
            <ChangePrimaryCard t={t} cardId={data as string} onClose={onClose} />
          )}
          {allowPopups &&
            popupType === PopupType.PromoDiscount &&
            (() => {
              const _data = data as {
                discountPercent?: number;
                discountPeriod: DurationUnit;
                discountPeriodCount?: number;
                code: string;
                type: CodeType;
              };
              return (
                <PromoDiscountDialog
                  discountPercent={_data.discountPercent}
                  discountPeriod={_data.discountPeriod}
                  discountPeriodCount={_data.discountPeriodCount}
                  code={_data.code}
                  type={_data.type}
                  onClose={onClose}
                />
              );
            })()}
          {allowPopups && popupType === PopupType.Cookies && (
            <CookiesPopup onClose={onClose} onAccept={updateTrackingAllowanceBasedOnCookies} />
          )}
          {allowPopups && popupType === PopupType.CookiesBar && (
            <CookiesBar onAccept={updateTrackingAllowanceBasedOnCookies} />
          )}
        </>
      )}
    </popupContext.Consumer>
  );
};

export default Layout;
