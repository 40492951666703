import BasePopup from './BasePopup';
import useTranslation from 'next-translate/useTranslation';
import { Namespace } from 'enums/namespaces';

interface Props {
  onClose?: () => void;
}

const LowQualityPopup = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation(Namespace.Popups);

  return (
    <BasePopup showCloseIcon={false} classes={[]} onClose={onClose}>
      <h2>{t('popups:low_quality_title')}</h2>
      <p>{t('popups:low_quality_text')}</p>
      <div className="buttons">
        <a onClick={onClose} className="btn btn-primary">
          {t('popups:low_quality_btn')}
        </a>
      </div>
    </BasePopup>
  );
};

export default LowQualityPopup;
