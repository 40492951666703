import { isLiveProduction, isProduction, localeToCountry } from 'helpers';

// local dev
let routeTable = {
  cz: 'czdramox:3300',
  sk: 'skdramox:3300',
  ua: 'uadramox:3300',
  pl: 'pldramox:3300',
  default: 'tvdramox:3300',
};

// live PROD
if (isLiveProduction) {
  routeTable = {
    cz: 'www.dramox.cz',
    sk: 'www.dramox.sk',
    ua: 'www.dramox.com.ua',
    pl: 'www.dramox.pl',
    default: 'www.dramox.tv',
  };
  // live DEV
} else if (isProduction) {
  routeTable = {
    cz: 'www.dev.dramox.cz',
    sk: 'www.dev.dramox.sk',
    ua: 'www.dev.dramox.com.ua',
    pl: 'www.dev.dramox.pl',
    default: 'www.dev.dramox.tv',
  };
}

export function baseUrl(locale: string): string {
  return `https://${routeTable[localeToCountry(locale)] ?? routeTable.default}`;
}

export default routeTable;
