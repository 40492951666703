import User from 'models/User';
import { Currency, Plan } from 'typings/api';

interface GtagEventIn {
  action: string;
  category: string;
  label: string;
  value?: number | string;
}

export const SEZNAM_CID_SUCCESS_SUBSCRIPTION = '100068650';
export const SEZNAM_CID_SUCCESS_VOUCHER = '100071337';
export const SEZNAM_CID_SUCCESS_REGISTRATION = '100071335';
const GA_ID = 'GTM-M27M94C';

type VIDEO_PROGRESS = 'start' | '5_seconds' | 'finish';

declare global {
  interface Window {
    gtag?: (type: string, body: { [index: string]: string | number }) => void;
    fbq: (action: string, o: string, b: { [index: string]: string | number }) => void;
    dataLayer?: any[];
  }
}

/* class */

export default class Analytics {
  /* Analytics for GTM */
  static v2_signUp() {
    Analytics.gtmEvent('sign_up');
  }

  static v2_subscriptionPurchase(plan: Plan) {
    Analytics.gtmEvent('subscription_purchase', {
      subscription_type: `${plan.recurrence.length}_${plan.recurrence.unit.toLowerCase()}`,
      currency: plan.price.currency,
      value: plan.price.value,
    });
  }

  static v2_subscriptionPurchaseWithCoupon(coupon: string) {
    Analytics.gtmEvent('subscription_purchase', { coupon });
  }

  static v2_subscriptionRenewal(plan: Plan) {
    Analytics.gtmEvent('subscription_renewal', {
      subscription_type: `${plan.recurrence.length}_${plan.recurrence.unit.toLowerCase()}`,
      currency: plan.price.currency,
      value: plan.price.value,
    });
  }

  static v2_subscriptionCancel(plan: Plan) {
    Analytics.gtmEvent('subscription_cancel', {
      subscription_type: `${plan.recurrence.length}_${plan.recurrence.unit.toLowerCase()}`,
      currency: plan.price.currency,
      value: plan.price.value,
    });
  }

  static v2_voucherPurchase(plan: Plan) {
    Analytics.gtmEvent('voucher_purchase', {
      subscription_type: `${plan.recurrence.length}_${plan.recurrence.unit.toLowerCase()}`,
      currency: plan.price.currency,
      value: plan.price.value,
    });
  }

  static v2_trailerView(title: string, theatreName: string, playProgress: VIDEO_PROGRESS) {
    Analytics.gtmEvent('trailer_view', { video_title: title, theater_name: theatreName, play_progress: playProgress });
  }

  static v2_playView(title: string, theatreName: string, playProgress: VIDEO_PROGRESS) {
    Analytics.gtmEvent('play_view', { video_title: title, theater_name: theatreName, play_progress: playProgress });
  }

  static v2_watchlistAdd(title: string, theatreName: string) {
    Analytics.gtmEvent('watchlist_add', { video_title: title, theater_name: theatreName });
    Analytics.facebookEvent('AddToWishlist');
  }

  static v2_watchlistRemove(title: string, theatreName: string) {
    Analytics.gtmEvent('watchlist_remove', { video_title: title, theater_name: theatreName });
    Analytics.facebookEvent('RemoveFromWatchlist');
  }

  static v2_theatreDonation(theatreName: string, currency: Currency, value: number) {
    Analytics.gtmEvent('theater_donation', { theater_name: theatreName, currency, value });
  }

  static v2_trialStart() {
    Analytics.gtmEvent('trial_start');
    Analytics.facebookEvent('StartTrial');
  }
  /* // Analytics for GTM */

  /* Facebook */
  static completeRegistration(): void {
    Analytics.facebookEvent('CompleteRegistration');
  }

  static setCurrentUrlForGoogle(path: string): void {
    const { gtag } = window;
    if (gtag) {
      gtag('config', { page_path: path });
    } else {
      console.log('GA setting current', path);
    }
  }

  private static facebookEvent(operation: string, body?: { [index: string]: string | number }): void {
    const { fbq } = window;
    if (fbq) {
      fbq('track', operation, body);
    } else {
      console.log('Facebook event logged', operation, body);
    }
  }

  private static gtmEvent = (eventName: string, params = {}) => {
    const data = Object.assign({ event: eventName }, params);

    if (window.dataLayer) {
      window.dataLayer.push(data);
    } else {
      console.log('GTM event logged', data);
    }
  };
}
