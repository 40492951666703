import * as yup from 'yup';
import { RequiredNumberSchema } from 'yup/lib/number';
import { RequiredStringSchema } from 'yup/lib/string';

export const clsLoading = 'loading';

export const createFormSchema = (object: {
  [key: string]:
    | RequiredStringSchema<string, Record<string, unknown>>
    | yup.NumberSchema<number, Record<string, unknown>, number>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
}): yup.ObjectSchema<unknown> => yup.object().shape(object);

export const notEmptyRule = (ruleNotEmpty: string): RequiredStringSchema<string> => yup.string().required(ruleNotEmpty);
export const passwordRule = (rulePassword: string, ruleNotEmpty: string): RequiredStringSchema<string> =>
  yup.string().min(8, rulePassword).required(ruleNotEmpty);
export const passwordAgainRule = (
  rulePassword: string,
  ruleNotEmpty: string,
  ruleDontMatch: string,
): RequiredStringSchema<string> =>
  yup
    .string()
    .min(8, rulePassword)
    .oneOf([yup.ref('password'), null], ruleDontMatch)
    .required(ruleNotEmpty);

export const numberRule = (rule: string): RequiredNumberSchema<number> => yup.number().required(rule);

export const donateRule = (
  ruleDonateMin: string,
  ruleDonateMax: string,
  min: number,
  max: number,
): RequiredNumberSchema<number> =>
  yup.number().min(min, ruleDonateMin).max(max, ruleDonateMax).required(ruleDonateMin).typeError(ruleDonateMin);

export const emailRule = (ruleEmail: string, ruleNotEmpty: string): RequiredStringSchema<string> =>
  yup
    .string()
    .matches(
      // same email validator as in muvi's tweaked jquery.validator plugin
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ruleEmail,
    )
    .required(ruleNotEmpty);

export const getFormInputs = (form: HTMLFormElement): HTMLInputElement[] =>
  Array.prototype.slice.apply(form.elements).filter((el) => el.tagName.toLowerCase() === 'input');

export const resetForm = (form: HTMLFormElement): void => {
  if (form) {
    form.classList.remove(clsLoading);
    getFormInputs(form).forEach((i) => (i.disabled = false));
  }
};

export const removeErrorClass = (form: HTMLFormElement): void => {
  Array.prototype.slice.call(form.elements).forEach((el) => el.classList.remove('error'));
};

export const setLoadingState = (form: HTMLFormElement): void => {
  form?.classList.add(clsLoading);
  getFormInputs(form).forEach((i) => (i.disabled = true));
};

export const validateForm = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formSchema: yup.ObjectSchema<any>,
  validateObject: { [key: string]: string | number },
  form: HTMLFormElement,
): boolean => {
  let valid = true;
  const formEls = form.elements;

  removeErrorClass(form);
  setLoadingState(form);

  try {
    formSchema.validateSync(validateObject, { abortEarly: false });
  } catch (e) {
    const error = e as yup.ValidationError;
    error.inner.forEach((err) => {
      const inputEl = formEls.namedItem(err.path) as HTMLInputElement;
      console.log('err.path ', err?.path);
      if (inputEl) {
        inputEl.classList.add('error');
        inputEl.nextElementSibling.textContent = err.message;
      }
    });

    valid = false;
  }

  return valid;
};
