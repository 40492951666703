import { MOBILE_VIEW_PX } from '../constants';

const CLS_DIALOG_OPEN = 'dialog-open';

export function closeDialog(cls?: string): void {
  getDOMCls().remove(CLS_DIALOG_OPEN, cls);
}

export const getDOM = (): Document => {
  return document;
};

export const getDOMCls = (): DOMTokenList => {
  return getDOM().documentElement.classList;
};

export const getHtmlEl = (): HTMLElement => {
  return getDOM().documentElement;
};

export const isMobileView = (): boolean => window.innerWidth < MOBILE_VIEW_PX;

export function toggleDialogWithCls(cls: string | string[], closeOnEsc = true, onClose?: () => void): void {
  if (!Array.isArray(cls)) {
    cls = [cls];
  }

  const elHtml = getHtmlEl();
  const list = getDOMCls();
  list.add(...cls, CLS_DIALOG_OPEN);

  if (closeOnEsc) {
    const close = () => {
      list.remove(...(cls as string[]), CLS_DIALOG_OPEN);
      elHtml.removeEventListener('keydown', closeKeyboardEvent, false);
      onClose && onClose();
    };

    const closeKeyboardEvent = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        close();
      }
    };

    elHtml.addEventListener('keydown', closeKeyboardEvent, false);
  }
}

export const debounce = <F extends (...args: unknown[]) => unknown>(
  func: F,
  waitFor = 10,
): ((...args: Parameters<F>) => Promise<unknown>) => {
  let timeout: NodeJS.Timeout;

  const a = async (...args: Parameters<F>): Promise<unknown> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });

  return a;
};
