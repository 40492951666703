import { host, isLiveProduction, localiseUrl } from 'helpers';
import Head from 'next/head';
import { useRouter } from 'next/router';
import routeTable, { baseUrl } from 'config/routeTable';
import { Country } from '../../enums/country';
import trackingScripts from './trackingScripts';

const Meta = ({ countryCode, analyticsAllowed }: { countryCode: string; analyticsAllowed: boolean }): JSX.Element => {
  const { locales, asPath, locale: currentLocale } = useRouter();

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `var a = document.documentElement; a.className = a.className.replace('no-js', 'js'); var e =
        window.navigator.userAgent, m = !!e.match(/iPad/i) || !!e.match(/iPhone/i), c = e.match(/android/i); (m || c) &&
        (a.className += ' mobile');`,
        }}
      />
      <meta property="og:site_name" content="Dramox" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1"
      />
      <meta name="author" content="https://elevup.com" />
      {locales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          href={`${baseUrl(locale)}${localiseUrl(asPath, locale, currentLocale)}`}
          hrefLang={locale}
        />
      ))}
      <link
        key="lang-default"
        rel="alternate"
        href={`${baseUrl('en')}${localiseUrl(asPath, 'en', currentLocale)}`}
        hrefLang="x-default"
      />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d73231" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#010117" />
      <meta name="theme-color" content="#010117" />
      <meta name="robots" content={isLiveProduction ? 'index,follow' : 'noindex,nofollow'} />
      {host() === routeTable.sk && (
        <meta name="facebook-domain-verification" content="fv5fwhmfbvqi4smhyxmhtz2fb0soz7" />
      )}
      {host() === routeTable.default && (
        <meta name="facebook-domain-verification" content="nqr715jnpkk9g3oftlo5rwikti6web" />
      )}

      {countryCode === Country.Ukraine && (
        <meta name="facebook-domain-verification" content="fv5fwhmfbvqi4smhyxmhtz2fb0soz7" />
      )}
      {analyticsAllowed && isLiveProduction && trackingScripts(countryCode)}
      {/* <!-- Clarity Tracking Code for https://www.dramox.cz/ --> */}
      {isLiveProduction && (
        <script
          id="clarity-tracking-code"
          dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "nbtk5ed8xf");
              `,
          }}
        />
      )}
    </Head>
  );
};

export default Meta;
