export enum DRMLicenseType {
  Widevine = 1,
  FairPlay = 2,
  PlayReady = 3,
}

export const DRMLicenseKey = {
  [DRMLicenseType.Widevine]: 'com.widevine.alpha',
  [DRMLicenseType.PlayReady]: 'com.microsoft.playready',
};
